import React, { useEffect, useState } from 'react'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import AciHighwayManifestApiUrls from 'src/apiUrls/AciHighwayManifestApiUrls'
import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'
import CngSection from '../../../components/cngcomponents/CngSection'
import moment from 'moment'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function History(props) {
  const { isf10Id } = props

  const [data, setData] = useState([])
  const classes = useStyles()
  const { fetchRecords } = useServices()
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_MANIFEST])
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    fetchRecords.execute(
      AceHighwayManifestApiUrls.GET_AUDIT_LOGS,
      {
        filters: [
          {
            field: 'keyReferenceNo',
            operator: EQUAL,
            value: 'ISF10_' + isf10Id
          }
        ]
      },
      (res) => {
        setData(res.content)
      }
    )
  }, [])

  function makeTranslatedTextsObject() {
    let createdDate = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CREATED_DATE_TIME
    )
    let loginUserId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.LOGIN_USER_ID
    )
    let functionCode = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FUNCTION_CODE
    )
    let auditDescription = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.AUDIT_DESC
    )

    return {
      createdDate,
      loginUserId,
      functionCode,
      auditDescription
    }
  }

  const columns = [
    { field: 'functionCode', title: translatedTextsObject.functionCode },
    {
      field: 'auditDescription',
      title: translatedTextsObject.auditDescription
    },
    {
      title: 'Account',
      render: (data) => (
        <div>
          <Typography>Account name and/or</Typography>
          <Typography variant='body2' color='textSecondary'>
            {data.userName}
          </Typography>
        </div>
      )
    },
    {
      title: translatedTextsObject.createdDate,
      render: (data) =>
        data.createdDate &&
        moment(data.createdDate).format('D MMM YYYY - HH:mm')
    }
  ]

  return (
    <CngSection title='History'>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((d) => (
                <TableRow key={d.id}>
                  {columns.map((column, index) => (
                    <TableCell key={index}>
                      {column.render ? column.render(d) : d[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </CngSection>
  )
}

export default History
