import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ISF10Keys from 'src/constants/locale/key/AceIsf'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  headerId: "",
  batchId: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ISF10)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let transaction = translate(
      Namespace.ISF10,
      ISF10Keys.Transaction.TITLE
    )
    let id = translate(
      Namespace.ISF10,
      ISF10Keys.Transaction.ID
    )
    let headerId = translate(
      Namespace.ISF10,
      ISF10Keys.Transaction.HEADER_ID
    )
    let batchId = translate(
      Namespace.ISF10,
      ISF10Keys.Transaction.BATCH_ID
    )

    return {
      transaction,
      id,
      headerId,
      batchId
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.transaction} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
            <CngTextField
              name="id"
              label={translatedTextsObject.id}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.headerId}>
            <CngTextField
              name="headerId"
              label={translatedTextsObject.headerId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.batchId}>
            <CngTextField
              name="batchId"
              label={translatedTextsObject.batchId}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const TransactionFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TransactionFormProperties
