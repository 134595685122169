import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices } from 'cng-web-lib'
import { Grid, Divider, Typography } from '@material-ui/core'
import { AceIsfTranslationContext } from '../../contexts/ACEISFTranslationContext'
import { NaPortUnladingAutocompleteField } from 'src/components/na'

const {
    button: { CngButton },
    form: {
        field: {
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
        },
    },
    CngGridItem,
} = components

function DeliveryLocationSection(props) {
    const { disabled, shouldHideMap, user, blTypes, showNotification } = props
    const { getTranslatedText } = useContext(AceIsfTranslationContext)
    const { countryUnlading, foreignPortUnlading, countryDelivery, foreignPlaceDelivery } = useWatch()
    return (
        <>
            <Grid container spacing={2}>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.countryUnlading}>
                    {/* <CngCodeMasterAutocompleteField
                        name='countryUnlading'
                        label={getTranslatedText('ACE_ISF', 'countryUnlading')}
                        codeType='ACEISF_SHIPMENT_TYPE'
                        size='small'
                        disabled={disabled}
                        key={countryUnlading}
                        lookupProps={{
                            label: (record) => `${record.code};${record.descriptionEn}`
                        }}
                    /> */}
                    <CngCountryAutocompleteField
                        name='countryUnlading'
                        label={getTranslatedText('ACE_ISF', 'countryUnlading')}
                        disabled={disabled}
                        key={countryUnlading}
                        lookupProps={{
                            label: (record) => {
                                return `${record.code};${record.descriptionEn}`
                            }
                        }}
                        size='small'
                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.foreignPortUnlading}>
                    {/* <CngCodeMasterAutocompleteField
                        required
                        name='foreignPortUnlading'
                        label={getTranslatedText('ACE_ISF', 'foreignPortUnlading')}
                        codeType='ACEISF_SHIPMENT_TYPE'
                        size='small'
                        disabled={disabled}
                        key={foreignPortUnlading}
                        lookupProps={{
                            label: (record) => `${record.code};${record.descriptionEn}`
                        }}
                    /> */}
                    <NaPortUnladingAutocompleteField
                        required
                        name="foreignPortUnlading"
                        label={getTranslatedText('ACE_ISF', 'foreignPortUnlading')}
                        disabled={disabled}
                        size='small'
                        key={foreignPortUnlading}
                        debounceInterval={1000}
                        openOnFocus={false}
                        onMouseDownCapture={(e) => e.stopPropagation()}
                    />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.countryDelivery}>
                    {/* <CngCodeMasterAutocompleteField
                        name='countryDelivery'
                        label={getTranslatedText('ACE_ISF', 'countryDelivery')}
                        codeType='ACEISF_SHIPMENT_TYPE'
                        size='small'
                        disabled={disabled}
                        key={countryDelivery}
                        lookupProps={{
                            label: (record) => `${record.code};${record.descriptionEn}`
                        }}
                    /> */}
                    <CngCountryAutocompleteField
                        name='countryDelivery'
                        label={getTranslatedText('ACE_ISF', 'countryDelivery')}
                        disabled={disabled}
                        key={countryDelivery}
                        lookupProps={{
                            label: (record) => {
                                return `${record.code};${record.descriptionEn}`
                            }
                        }}
                        size='small'
                    />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.foreignPlaceDelivery}>
                    {/* <CngCodeMasterAutocompleteField
                        required
                        name='foreignPlaceDelivery'
                        label={getTranslatedText('ACE_ISF', 'foreignPlaceDelivery')}
                        codeType='ACEISF_SHIPMENT_TYPE'
                        size='small'
                        disabled={disabled}
                        key={foreignPlaceDelivery}
                        lookupProps={{
                            label: (record) => `${record.code};${record.descriptionEn}`
                        }}
                    /> */}
                    <NaPortUnladingAutocompleteField
                        required
                        name="foreignPlaceDelivery"
                        label={getTranslatedText('ACE_ISF', 'foreignPlaceDelivery')}
                        disabled={disabled}
                        size='small'
                        key={foreignPlaceDelivery}
                        debounceInterval={1000}
                        openOnFocus={false}
                        onMouseDownCapture={(e) => e.stopPropagation()}
                    />
                </CngGridItem>
            </Grid>
        </>
    )
}

export default DeliveryLocationSection