import React, { useEffect, useState, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import HtsDialog from './HtsDialog'
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL }
} = constants

function ContainerTable(props) {
  const {
    data,
    onAddHts,
    onDeleteHts,
    onEditHts,
    showNotification,
    shouldHideMap,
    disabled
  } = props

  const [lookups, setLookups] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [htsDTODialog, setHtsDTODialog] = useState({
    isView: false,
    open: false,
    htsDTO: null
  })
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const classes = useStyles()
  const { getTranslatedText } = useContext(AceIsfTranslationContext)

  const filtered =
    searchTerm !== ''
      ? data.filter((htsDTO) =>
        htsDTO.htsNo
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      : data

  useEffect(() => {
    Promise.all([
      // Cargo loaded in
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_TRAILER_TYPE' }],
        undefined,
        'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: EQUAL, value: 'ACI' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      )
    ]).then(
      ([
        cargoLoadedIn,
        countries,
        countryStates
      ]) => {
        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setLookups({
          cargoLoadedIn,
          countries,
          countryStates: states
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddHts && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={() =>
                  setHtsDTODialog({
                    open: true,
                    isView: false,
                    htsDTO: null
                  })
                }
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Harmonized Tariff Schedule (HTS)
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{getTranslatedText('ACE_ISF', 'htsNo')}</TableCell>
              <TableCell>{getTranslatedText('ACE_ISF', 'countryOrigin')}</TableCell>
              {(onEditHts || onDeleteHts) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((htsDTO) => (
                <TableRow
                  key={Math.random()}
                  // onClick={() =>
                  //   setHtsDTODialog({ open: true, isView: true, htsDTO })
                  // }
                >
                  <TableCell>{htsDTO.htsNo}</TableCell>
                  <TableCell>{htsDTO.countryOrigin}</TableCell>
                  {(onEditHts || onDeleteHts) && (
                    <TableCell align='right'>
                      <Grid container justify='flex-end' spacing={1}>
                        {onEditHts && (
                          <Grid item xs='auto'>
                            <Tooltip placement='bottom' title='Edit'>
                              <span>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'pen']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    setHtsDTODialog({ open: true, isView: false, htsDTO })
                                  }}
                                  size='small'
                                />
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                        {onDeleteHts && (
                          <Grid item xs='auto'>
                            <Tooltip placement='bottom' title='Delete'>
                              <span>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'trash']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    onDeleteHts(htsDTO)
                                  }}
                                  size='small'
                                />
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item xs='auto'>
                          <Tooltip placement='bottom' title='Clone'>
                            <span>
                              <CngIconButton
                                icon={['fal', 'copy']}
                                onClick={() => onAddHts({ ...htsDTO, id: undefined })}
                                size='small'
                                type='outlined'
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditHts || onDeleteHts ? 1 : 0)}
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <HtsDialog
        getLookupValue={getLookupValue}
        getCountryStateLabel={getCountryStateLabel}
        isView={htsDTODialog.isView}
        open={htsDTODialog.open}
        onClose={() =>
          setHtsDTODialog({ open: false, isView: false, htsDTO: null })
        }
        onAddHtsDTO={onAddHts}
        onEditHtsDTO={onEditHts}
        showNotification={showNotification}
        htsDTO={htsDTODialog.htsDTO}
      />
    </>
  )
}

export default ContainerTable
