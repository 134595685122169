import makeValidationSchema from './ContainerMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import { Card, CardContent, Grid } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form';
import {
  components,
} from 'cng-web-lib'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import CngSection from '../../../components/cngcomponents/CngSection'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  headerId: "",
  containerNo: "",
  descriptionCode: "",
  typeCode: "",
  containerInitial: "",
  sizeTypeCode: "",
  version: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { getValues, setValue, reset, trigger } = useFormContext();
  const { getTranslatedText } = useContext(AceIsfTranslationContext);
  const descriptionCode = useWatch({ name: 'descriptionCode' });
  const typeCode = useWatch({ name: 'typeCode' });

  return (
    <>
      <CngSection title="Container Details">
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.containerNo}>
            <CngTextField
              required
              name='containerNo'
              label={getTranslatedText('ACE_ISF', 'containerNo')}
              disabled={disabled}
              inputProps={{ maxLength: 19 }}
              onChange={(e) => {
                setValue('containerNo', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.descriptionCode}>
            <CngCodeMasterAutocompleteField
              required
              name='descriptionCode'
              label={getTranslatedText('ACE_ISF', 'descriptionCode')}
              codeType='ACEISF_DESC_CODE'
              size='small'
              onChange={(e) => {
                let tempDescriptionCode = e ? e : "";
                setValue("sizeTypeCode", typeCode ? (tempDescriptionCode + typeCode) : "", { shouldValidate: true })
              }}
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.typeCode}>
            <CngCodeMasterAutocompleteField
              name='typeCode'
              label={getTranslatedText('ACE_ISF', 'typeCode')}
              codeType='ACEISF_TYPE_CODE'
              size='small'
              onChange={(e) => {
                let tempTypeCode = e ? e : "";
                setValue("sizeTypeCode", descriptionCode ? (descriptionCode + tempTypeCode) : tempTypeCode, { shouldValidate: true })
              }}
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.sizeTypeCode}>
            <CngTextField
              name='sizeTypeCode'
              label={getTranslatedText('ACE_ISF', 'sizeTypeCode')}
              disabled={true}
              inputProps={{ maxLength: 4 }}
              onChange={(e) => {
                setValue('sizeTypeCode', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </CngSection>
    </>
  )
}

const ContainerFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ContainerFormProperties
