import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext } from 'react'
import Namespace from 'src/constants/locale/Namespace'

import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import { useFormContext, useWatch } from 'react-hook-form';

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _shipmentId: 0,
  id:"",
  headerId: "",
  blType: "",
  blNo: "",
  blMatched: "",
  importerRefNo: "",
  partyId: "",
  version: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { getTranslatedText } = useContext(AceIsfTranslationContext)
  const { getValues, setValue, reset, trigger } = useFormContext();
  const templateFlag = useWatch({ name: 'templateFlag' });
  const isEditTemplate = useWatch({ name: 'isEditTemplate' });

  return (
    <Card>
      {/* <CngSimpleCardHeader title={translatedTextsObject.shipment} /> */}
      <CardContent>
        <Grid container spacing={2}>
          <CngGridItem xs={4} sm={4} shouldHide={shouldHideMap.blType}>
            <CngCodeMasterAutocompleteField
              required
              name={typeof index === 'number' ? `shipments.${index}.blType` : 'blType'}
              label={getTranslatedText('ACE_ISF', 'blType')}
              codeType='ACEISF_BL_TYPE'
              size='small'
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={5} sm={5} shouldHide={shouldHideMap.blNo}>
            <CngTextField
              required={!templateFlag}
              name={typeof index === 'number' ? `shipments.${index}.blNo` : 'blNo'}
              label={getTranslatedText('ACE_ISF', 'blNo')}
              disabled={disabled || (isEditTemplate && templateFlag)}
              inputProps={{ maxLength: 50 }}
              onBlur={(e) => {
                setValue('blNo', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={3} sm={3} shouldHide={shouldHideMap.blMatched}>
            <CngTextField
              name={typeof index === 'number' ? `shipments.${index}.blMatched` : 'blMatched'}
              label={getTranslatedText('ACE_ISF', 'blMatched')}
              disabled={true}
              inputProps={{ maxLength: 30 }}
              onChange={(e) => {
                setValue('blMatched', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const ShipmentFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ShipmentFormProperties
