import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, constants } from 'cng-web-lib'
import FormProperties from './HtsFormProperties'
import { Box, Grid } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function HtsDialog(props) {
  const {
    getLookupValue,
    getCountryStateLabel,
    onAddHtsDTO,
    onEditHtsDTO,
    isView,
    onClose,
    open,
    showNotification,
    htsDTO
  } = props

  const { initialValues, makeValidationSchema } = FormProperties.formikProps
  const { getTranslatedText } = useContext(AceIsfTranslationContext)

  return (
    <CngDialog
      dialogContent={
        isView && htsDTO ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CngSection title={getTranslatedText('ACE_ISF', 'hts')}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('ACE_ISF', 'htsNo')}>
                      {htsDTO.htsNo}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('ACE_ISF', 'countryOrigin')}>
                      {htsDTO.countryOrigin}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          </Grid>
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: htsDTO || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (htsDTO) {
                  onEditHtsDTO(data)
                } else {
                  onAddHtsDTO(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <Box padding={1}>
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              </Box>
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton type='submit'>
                  {htsDTO
                    ? getTranslatedText('ACE_ISF', 'updateButton')
                    : getTranslatedText('ACE_ISF', 'saveButton')}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      // dialogTitle={translatedTextsObject.trailerDTOTitle}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default HtsDialog
