import ISF10ApiUrls from 'src/apiUrls/ISF10ApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useState, useContext, useMemo } from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import Isf10ViewPage from './Isf10ViewPage'
import ResponseMessage from './ResponseMessage'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'

const {
  form: { CngViewForm },
  CngTabs
} = components

function ViewPage(props) {
  const { location, showNotification } = props
  const { state } = location
  const { id } = useParams()
  const { getTranslatedText } = useContext(AceIsfTranslationContext)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs activeTabIndex={state?.active ? state.active : 0}
          headerColor='primary'
          tabs={[
            {
              tabName: getTranslatedText('ACE_ISF', 'viewIsf') + " 10",
              tabContent: (
                <Isf10ViewPage
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
            {
              tabName: getTranslatedText('ACE_ISF', 'response'),
              tabContent: (
                <ResponseMessage
                  showNotification={showNotification}
                  id={id}
                  isViewOnly={true}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default ViewPage
