import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices, constants } from 'cng-web-lib'
import { Grid, Divider, Typography } from '@material-ui/core'
import { AceIsfTranslationContext } from '../../contexts/ACEISFTranslationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import { useLocation } from 'react-router-dom'
import CngSection from '../../../../components/cngcomponents/CngSection'
import Switch from 'src/components/Switch'
import ShipmentFormProperties from '../ShipmentFormProperties'
import ShipmentEntry from '../ShipmentEntry'
import TemplateSelectDialogWithOption from '../../isf5/TemplateSelectDialogWithOption'

const {
    button: { CngButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
            CngSelectField,
            CngUomAutocompleteField
        },
    },
    CngGridItem,
} = components

const {
    filter: { LIKE, EQUAL, IN }
} = constants

function HeaderDetailsSection(props) {
    const { disabled, shouldHideMap, user, blTypes, showNotification } = props
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const { getTranslatedText } = useContext(AceIsfTranslationContext)
    const location = useLocation();
    const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false
    const [isf5TemplateDialog, setIsf5TemplateDialog] = useState(false)
    const templateFlag = useWatch({ name: 'templateFlag' });
    const myReferenceValues = useWatch({ name: ['refBookingNo', 'portOfLading', 'estimateLoadingDate'] })
    const countryIssuance = useWatch({ name: 'countryIssuance' });
    const fromTemplate = useWatch({ name: 'fromTemplate' });
    const isEditTemplate = getValues('isEditTemplate')
    const cbpBondFlag = useWatch({ name: 'cbpBond' });
    const { _id: _shipmentId, shipments, shipmentType, importerDocType, modeOfTransport, bondHolderDocType, bondType, bondTerm } = useWatch()

    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        isf5: null
    })

    useEffect(() => {
        if (!editPage && !fromTemplate && user && user.aceIsfFileReference && user.aceIsfFileReference.useDefault) {
            setValue('importerDocType', user.aceIsfFileReference.importerDocType)
            setValue('importerRefNo', user.aceIsfFileReference.importerRefNo)
            setValue('cbpBond', user.aceIsfFileReference.cbpBond)
            setValue('bondType', user.aceIsfFileReference.bondType)
            setValue('bondHolderDocType', user.aceIsfFileReference.bondHolderDocType)
            setValue('bondHolderDocNo', user.aceIsfFileReference.bondHolderDocNo)
            setValue('bondTerm', user.aceIsfFileReference.bondTerm)
            setValue('bondTermRefNo', user.aceIsfFileReference.bondTermRefNo)
            setValue('bondTermSuretyCode', user.aceIsfFileReference.bondTermSuretyCode)
            setValue('agentCode', user.aceIsfFileReference.agentCode)
        }
    }, [editPage, user])

    useEffect(() => {
        if (shipments.length === 0) {
            const { initialValues } = ShipmentFormProperties.formikProps
            setValue('shipments', [{ ...initialValues, _shipmentId, _id: 1 }])
        }
    }, [shipments])

    function handleAddShipment(data) {
        const personDocumentDTO = [...getValues('shipments'), data].map(
            (document, index) => ({ ...document, _shipmentId, _id: index + 1 })
        )

        setValue('shipments', personDocumentDTO, { shouldDirty: true })
    }

    function handleDeleteShipment(data) {
        const personDocumentDTO = [...getValues('shipments')].filter((document) =>
            document.id ? document.id !== data.id : document._id !== data._id
        )

        setValue('shipments', personDocumentDTO, { shouldDirty: true })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap.templateFlag}>
                            <CngCheckboxField
                                label={
                                    <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                                        {getTranslatedText('ACE_ISF', 'templateFlag')}
                                    </Typography>
                                }
                                name='templateFlag'
                                disabled={disabled || isEditTemplate}
                                onChange={(e) => setValue('templateFlag', e.target.checked)}
                                size='small'
                                style={{ marginLeft: 4, marginRight: 4, padding: 8 }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} lg shouldHide={shouldHideMap.templateName}>
                            <CngTextField
                                name='templateName'
                                inputProps={{ maxLength: 50 }}
                                placeholder={getTranslatedText('ACE_ISF', 'templateName')}
                                disabled={disabled || templateFlag === false || isEditTemplate}
                                onChange={(e) => { setValue('templateName', e.target.value.toUpperCase()) }}
                                onBlur={(e) => {
                                    if (e.target.value !== undefined) {
                                        setValue('templateName', e.target.value.toUpperCase().trim())
                                    }
                                }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} lg='auto'>
                            <CngButton size='medium' color='secondary' onClick={() => setIsf5TemplateDialog(true)}
                                startIcon={<FontAwesomeIcon icon={['fal', 'money-check-edit']} />}>
                                Use template
                            </CngButton>
                        </CngGridItem>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CollapsibleSection
                        defaultExpanded={myReferenceValues.filter((value) => value).length > 0}
                        title={getTranslatedText('ACE_ISF', 'myReference')}
                    >
                        <Grid container spacing={1}>
                            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.refBookingNo}>
                                <CngTextField
                                    name='refBookingNo'
                                    label={getTranslatedText('ACE_ISF', 'refBookingNo')}
                                    disabled={disabled}
                                    inputProps={{ maxLength: 25 }}
                                    onChange={(e) => {
                                        setValue('refBookingNo', e.target.value.toUpperCase())
                                    }}
                                    size='small'
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.portOfLading}>
                                <CngTextField
                                    name='portOfLading'
                                    label={getTranslatedText('ACE_ISF', 'portOfLading')}
                                    disabled={disabled}
                                    inputProps={{ maxLength: 25 }}
                                    onChange={(e) => {
                                        setValue('portOfLading', e.target.value.toUpperCase())
                                    }}
                                    size='small'
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.estimateLoadingDate}>
                                <CngDateField
                                    disabled={disabled}
                                    name='estimateLoadingDate'
                                    label={getTranslatedText('ACE_ISF', 'estimateLoadingDate')}
                                    format='YYYY-MM-DD'
                                    size='small'
                                />
                            </CngGridItem>
                        </Grid>
                    </CollapsibleSection>
                </Grid>
                <Grid item xs={12}>
                    <CngSection title={getTranslatedText('ACE_ISF', 'isfShipment')}
                        subheader={
                            <Typography color='textSecondary' variant='caption'>
                                <Typography variant='inherit' color='error'>*</Typography>
                                Mandatory fields
                            </Typography>
                        }
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.cbpTransactionNo}>
                                        <CngTextField
                                            name='cbpTransactionNo'
                                            label={getTranslatedText('ACE_ISF', 'cbpTransactionNoInfo')}
                                            disabled={true}
                                            onChange={(e) => {
                                                setValue('cbpTransactionNo', e.target.value.toUpperCase())
                                            }}
                                            size='small'
                                        />
                                    </CngGridItem>
                                    <Grid item xs={12}>
                                        <CngSection title={getTranslatedText('ACE_ISF', 'shipmentReference')}>
                                            <Grid container spacing={1}>
                                                {shipments.map((shipment, index) => (
                                                    <React.Fragment key={shipment._id || shipment.id || index}>
                                                        <Grid item xs={12}>
                                                            <ShipmentEntry
                                                                index={index}
                                                                isLast={shipments.length === 1}
                                                                onAddShipment={handleAddShipment}
                                                                onDeleteShipment={handleDeleteShipment}
                                                                shipment={shipment}
                                                                showNotification={showNotification}
                                                                shouldHideMap={shouldHideMap}
                                                            />
                                                        </Grid>
                                                        {shipments.length !== index + 1 && (
                                                            <Grid item xs={12}>
                                                                <Divider />
                                                            </Grid>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </Grid>
                                        </CngSection>
                                    </Grid>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.shipmentType}>
                                        <CngCodeMasterAutocompleteField
                                            required
                                            name='shipmentType'
                                            label={getTranslatedText('ACE_ISF', 'shipmentType')}
                                            codeType='ACEISF5_SHIPMENT_TYPE'
                                            size='small'
                                            disabled={disabled}
                                            key={shipmentType}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.modeOfTransport}>
                                        <CngCodeMasterAutocompleteField
                                            required
                                            name='modeOfTransport'
                                            label={getTranslatedText('ACE_ISF', 'modeOfTransport')}
                                            codeType='ACEISF_MODE_OF_TRANSPORT'
                                            size='small'
                                            disabled={disabled}
                                            key={modeOfTransport}
                                        />
                                    </CngGridItem>
                                    {shipmentType === '06' &&
                                        <>
                                            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.carnetNumber}>
                                                <CngTextField
                                                    required
                                                    name='carnetNumber'
                                                    label={getTranslatedText('ACE_ISF', 'carnetNumber')}
                                                    disabled={disabled}
                                                    inputProps={{ maxLength: 50 }}
                                                    onChange={(e) => {
                                                        setValue('carnetNumber', e.target.value.toUpperCase())
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                        </>
                                    }
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.entryNo}>
                                        <CngTextField
                                            name='entryNo'
                                            label={getTranslatedText('ACE_ISF', 'entryNo')}
                                            disabled={disabled}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(e) => {
                                                setValue('entryNo', e.target.value.toUpperCase())
                                            }}
                                            size='small'
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.masterBL}>
                                        <CngTextField
                                            name='masterBL'
                                            label={getTranslatedText('ACE_ISF', 'masterBL')}
                                            disabled={disabled}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(e) => {
                                                setValue('masterBL', e.target.value.toUpperCase())
                                            }}
                                            size='small'
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.scac}>
                                        <CngTextField
                                            name='scac'
                                            label={getTranslatedText('ACE_ISF', 'scac')}
                                            disabled={disabled}
                                            inputProps={{ maxLength: 4 }}
                                            onChange={(e) => {
                                                setValue('scac', e.target.value.toUpperCase())
                                            }}
                                            size='small'
                                        />
                                    </CngGridItem>

                                    {shipmentType === '11' &&
                                        <>
                                            <Grid item xs={12}>
                                                &nbsp;
                                                <Grid alignItems='center' container spacing={2}>
                                                    <CngGridItem xs={12} sm='auto'>
                                                        <Typography variant='body2'>
                                                            Shipment info
                                                        </Typography>
                                                    </CngGridItem>
                                                    <CngGridItem xs={12} sm><Divider /></CngGridItem>
                                                </Grid>
                                                &nbsp;
                                            </Grid>

                                            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.shipmentSubType}>
                                                <CngCodeMasterAutocompleteField
                                                    required
                                                    name='shipmentSubType'
                                                    label={getTranslatedText('ACE_ISF', 'shipmentSubType')}
                                                    codeType='ACEISF_SHIP_SUB_TYPE'
                                                    size='small'
                                                    disabled={disabled}
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.estimatedValue}>
                                                <CngTextField
                                                    required
                                                    name='estimatedValue'
                                                    type="number"
                                                    label={getTranslatedText('ACE_ISF', 'estimatedValue')}
                                                    disabled={disabled}
                                                    onChange={(e) => {
                                                        setValue('estimatedValue', e.target.value.toUpperCase())
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.estimatedQuantity}>
                                                <CngTextField
                                                    required
                                                    name='estimatedQuantity'
                                                    type="number"
                                                    label={getTranslatedText('ACE_ISF', 'estimatedQuantity')}
                                                    disabled={disabled}
                                                    onChange={(e) => {
                                                        setValue('estimatedQuantity', e.target.value.toUpperCase())
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.unitOfMeasure}>
                                                <CngUomAutocompleteField
                                                    required
                                                    name="unitOfMeasure"
                                                    label={getTranslatedText('ACE_ISF', 'unitOfMeasure')}
                                                    disabled={disabled}
                                                    lookupProps={{
                                                        label: (record) => {
                                                            return `${record.code};${record.descriptionEn}`
                                                        },
                                                        filters: [{
                                                            field: 'uomType',
                                                            operator: 'equal',
                                                            value: 'ACI'
                                                        }]
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.estimatedWeight}>
                                                <CngTextField
                                                    required
                                                    name='estimatedWeight'
                                                    type="number"
                                                    label={getTranslatedText('ACE_ISF', 'estimatedWeight')}
                                                    disabled={disabled}
                                                    onChange={(e) => {
                                                        setValue('estimatedWeight', e.target.value.toUpperCase())
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.weightQualifiers}>
                                                <CngCodeMasterAutocompleteField
                                                    required
                                                    name="weightQualifiers"
                                                    label={getTranslatedText('ACE_ISF', 'weightQualifiers')}
                                                    disabled={disabled}
                                                    codeType='ACEISF_WEIGHT_QUALIFIERS'
                                                    size='small'
                                                />
                                            </CngGridItem>
                                        </>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CngSection>
                </Grid>
                <Grid item xs={12}>
                    <CngSection title={getTranslatedText('ACE_ISF', 'filerReference')}
                        subheader={
                            <Typography color='textSecondary' variant='caption'>
                                <Typography variant='inherit' color='error'>*</Typography>
                                Mandatory fields
                            </Typography>
                        }
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.importerDocType}>
                                        <CngCodeMasterAutocompleteField
                                            required
                                            name='importerDocType'
                                            label={getTranslatedText('ACE_ISF', 'importerDocType')}
                                            codeType='ACEISF_IMP_HOL_DOC_TYPE'
                                            size='small'
                                            disabled={disabled}
                                            key={importerDocType}
                                            lookupProps={{
                                                filters: [
                                                    { field: 'code', operator: IN, value: ['EI', 'ANI', '34', 'AEF', '2'] }
                                                ]
                                            }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.importerRefNo}>
                                        <CngTextField
                                            required={!templateFlag}
                                            name='importerRefNo'
                                            label={getTranslatedText('ACE_ISF', 'importerRefNo')}
                                            disabled={disabled || (templateFlag && isEditTemplate)}
                                            inputProps={{ maxLength: (importerDocType === 'EI' ? 12 : importerDocType === '34' ? 11 : importerDocType === 'ANI' ? 12 : 15) }}
                                            onChange={(e) => {
                                                let word = e.target.value.toUpperCase()
                                                let word2 = word.substring(4, 11)

                                                if (importerDocType === 'EI' && word.length === 3 && !word.includes('-')) {
                                                    word = word.substring(0, 2) + '-' + word.substring(2, 10)
                                                } else if (importerDocType === '34' && word.length === 4 && !word.includes('-')) {
                                                    word = word.substring(0, 3) + '-' + word.substring(3, 6)
                                                } else if (importerDocType === '34' && word2.length === 3 && !word2.includes('-')) {
                                                    word = word.substring(0, 6) + '-' + word.substring(6, 10)
                                                } else if (importerDocType === 'ANI' && word.length === 7 && !word.includes('-')) {
                                                    word = word.substring(0, 6) + '-' + word.substring(6, 11)
                                                }

                                                setValue('importerRefNo', word)
                                            }}
                                            placeholder={importerDocType === 'EI' ? 'NN-NNNNNNNXX' : importerDocType === '34' ? 'NNN-NN-NNNN' : importerDocType === 'ANI' ? 'YYDDPP-NNNNN' : ''}
                                            // onChange={(e) => {
                                            //     setValue('importerRefNo', e.target.value.toUpperCase())
                                            // }}
                                            size='small'
                                        />
                                    </CngGridItem>
                                    {importerDocType === 'AEF' &&
                                        <>
                                            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.dateOfBirth}>
                                                <CngDateField
                                                    required
                                                    disabled={disabled}
                                                    name='dateOfBirth'
                                                    label={getTranslatedText('ACE_ISF', 'dateOfBirth')}
                                                    format='YYYY-MM-DD'
                                                    size='small'
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.name}>
                                                <CngTextField
                                                    required
                                                    name='name'
                                                    label={getTranslatedText('ACE_ISF', 'name')}
                                                    disabled={disabled}
                                                    inputProps={{ maxLength: 15 }}
                                                    onChange={(e) => {
                                                        setValue('name', e.target.value.toUpperCase())
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.countryIssuance}>
                                                <CngCountryAutocompleteField
                                                    required
                                                    name='countryIssuance'
                                                    label={getTranslatedText('ACE_ISF', 'countryIssuance')}
                                                    disabled={disabled}
                                                    key={countryIssuance}
                                                    onChange={() => {
                                                        setValue('province', '')
                                                    }}
                                                    lookupProps={{
                                                        label: (record) => `${record.code};${record.descriptionEn}`
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                        </>}
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap?.cbpBond}>
                                        <Switch
                                            fullWidth
                                            name="cbpBond"
                                            label={getTranslatedText('ACE_ISF', 'cbpBond')}
                                            disabled={disabled}
                                            onChange={(event) => {
                                                setValue('cbpBond', event.target.checked, { shouldDirty: true })

                                                setValue('bondType', "", { shouldDirty: true })
                                                setValue('bondHolderDocType', "", { shouldDirty: true })
                                                setValue('bondHolderDocNo', "", { shouldDirty: true })
                                                setValue('bondTerm', "", { shouldDirty: true })
                                                setValue('bondTermRefNo', "", { shouldDirty: true })
                                                setValue('bondTermSuretyCode', "", { shouldDirty: true })
                                            }}
                                        />
                                    </CngGridItem>
                                    {cbpBondFlag && <>
                                        <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.bondType}>
                                            <CngCodeMasterAutocompleteField
                                                required
                                                name='bondType'
                                                label={getTranslatedText('ACE_ISF', 'bondType')}
                                                codeType='ACEISF_BOND_TYPE'
                                                size='small'
                                                disabled={disabled}
                                                key={bondType}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.bondHolderDocType}>
                                            <CngCodeMasterAutocompleteField
                                                required
                                                name='bondHolderDocType'
                                                label={getTranslatedText('ACE_ISF', 'bondHolderDocType')}
                                                codeType='ACEISF_IMP_HOL_DOC_TYPE'
                                                size='small'
                                                disabled={disabled}
                                                key={bondHolderDocType}
                                                lookupProps={{
                                                    filters: [
                                                        { field: 'code', operator: IN, value: ['EI', 'ANI', '34', '2'] }
                                                    ]
                                                }}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.bondHolderDocNo}>
                                            <CngTextField
                                                required
                                                name='bondHolderDocNo'
                                                label={getTranslatedText('ACE_ISF', 'bondHolderDocNo')}
                                                disabled={disabled}
                                                size='small'
                                                inputProps={{ maxLength: (bondHolderDocType === 'EI' ? 12 : bondHolderDocType === '34' ? 11 : bondHolderDocType === 'ANI' ? 12 : 15) }}
                                                onChange={(e) => {
                                                    let word = e.target.value.toUpperCase()
                                                    let word2 = word.substring(4, 11)

                                                    if (bondHolderDocType === 'EI' && word.length === 3 && !word.includes('-')) {
                                                        word = word.substring(0, 2) + '-' + word.substring(2, 10)
                                                    } else if (bondHolderDocType === '34' && word.length === 4 && !word.includes('-')) {
                                                        word = word.substring(0, 3) + '-' + word.substring(3, 6)
                                                    } else if (bondHolderDocType === '34' && word2.length === 3 && !word2.includes('-')) {
                                                        word = word.substring(0, 6) + '-' + word.substring(6, 10)
                                                    } else if (bondHolderDocType === 'ANI' && word.length === 7 && !word.includes('-')) {
                                                        word = word.substring(0, 6) + '-' + word.substring(6, 11)
                                                    }

                                                    setValue('bondHolderDocNo', word)
                                                }}
                                                placeholder={bondHolderDocType === 'EI' ? 'NN-NNNNNNNXX' : bondHolderDocType === '34' ? 'NNN-NN-NNNN' : bondHolderDocType === 'ANI' ? 'YYDDPP-NNNNN' : ''}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.bondTerm}>
                                            <CngCodeMasterAutocompleteField
                                                required
                                                name='bondTerm'
                                                label={getTranslatedText('ACE_ISF', 'bondTerm')}
                                                codeType='ACEISF_BOND_TERM'
                                                size='small'
                                                disabled={disabled}
                                                key={bondTerm}
                                                onChange={(event) => {
                                                    setValue('bondTermRefNo', "", { shouldDirty: true })
                                                    setValue('bondTermSuretyCode', "", { shouldDirty: true })
                                                }}
                                            />
                                        </CngGridItem>
                                        {bondType === '16' && bondTerm === '9' && <>
                                            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.bondTermRefNo}>
                                                <CngTextField
                                                    required
                                                    name='bondTermRefNo'
                                                    label={getTranslatedText('ACE_ISF', 'bondTermRefNo')}
                                                    disabled={disabled}
                                                    inputProps={{ maxLength: 30 }}
                                                    onChange={(e) => {
                                                        setValue('bondTermRefNo', e.target.value.toUpperCase())
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.bondTermSuretyCode}>
                                                <CngTextField
                                                    required
                                                    name='bondTermSuretyCode'
                                                    label={getTranslatedText('ACE_ISF', 'bondTermSuretyCode')}
                                                    disabled={disabled}
                                                    inputProps={{ maxLength: 30 }}
                                                    onChange={(e) => {
                                                        setValue('bondTermSuretyCode', e.target.value.toUpperCase())
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                        </>}
                                    </>}
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.agentCode}>
                                        <CngTextField
                                            required
                                            name='agentCode'
                                            label={getTranslatedText('ACE_ISF', 'agentCode')}
                                            disabled={disabled}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(e) => {
                                                setValue('agentCode', e.target.value.toUpperCase())
                                            }}
                                            size='small'
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CngSection>
                </Grid>
            </Grid>
            <TemplateSelectDialogWithOption
                isfTemplateDialog={isf5TemplateDialog}
                setIsfTemplateDialog={setIsf5TemplateDialog}
                setConfirmDialog={setConfirmDialog}
                showNotification={showNotification}
                setValue={setValue}
                reset={reset}
                trigger={trigger}
            />
        </>
    )
}

export default HeaderDetailsSection