import ISF5ApiUrls from 'src/apiUrls/ISF5ApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import { useFormContext, useWatch } from 'react-hook-form';
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import axios from 'axios'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import ResponseMessage from './ResponseMessage'

const {
  button: { CngButton },
  form: { CngEditForm },
  CngTabs,
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const { location, showNotification } = props
  const history = useHistory()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const { state } = location
  const serverDataRef = useRef()
  const { getTranslatedText } = useContext(AceIsfTranslationContext)
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [isSuperParty, setSuperParty] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([])
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function preSubmit() {
    const values = { ...serverDataRef.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR'
      serverDataRef.current = values
      handleSubmitIsf5()
    }
  }

  function handleSubmitIsf5() {
    setLoading(true)

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      serverDataRef.current.fileForUserId = fileForUserDetails.fileForUserId;
      serverDataRef.current.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      serverDataRef.current.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    instance
      .post(
        ISF5ApiUrls.SUBMIT,
        DataFlattener.unflatten(serverDataRef.current)
      )
      .then((result) => {
        var returnData = result.data
        var errorMessages = returnData.errorMessages
        if (
          result.status == 200 &&
          returnData != null &&
          errorMessages == null
        ) {
          showNotification('success', getTranslatedText('ACE_ISF', 'submitSuccessMsg'))
          history.push(pathMap.ACEISF5_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngTabs activeTabIndex={state?.active ? state.active : 0}
            headerColor='primary'
            tabs={[
              {
                tabName: getTranslatedText('ACE_ISF', 'editISF5'),
                tabContent: (
                  <CngEditForm
                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                    showNotification={showNotification}
                    renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                      <FormProperties.Fields
                        disabled={false}
                        showNotification={showNotification}
                        shouldHideMap={shouldHideMap}
                      />
                    )}
                    formikProps={FormProperties.formikProps}
                    toClientDataFormat={FormProperties.toClientDataFormat}
                    toServerDataFormat={FormProperties.toServerDataFormat}
                    fetch={{
                      url: ISF5ApiUrls.GET,
                      onPreSuccess: (serverData) => {
                        serverData.isEditTemplate = serverData.templateFlag
                      }
                    }}
                    update={{
                      url: ISF5ApiUrls.PUT
                    }}
                    id={id}
                    renderButtonSection={() => (
                      <ButtonSection
                        history={history}
                        loading={loading}
                        onSubmitCargo={(data) => {
                          serverDataRef.current = data
                          preSubmit()
                        }}
                      />
                    )}
                  />
                )
              },
              {
                tabName: getTranslatedText('ACE_ISF', 'response'),
                tabContent: (
                  <ResponseMessage
                    showNotification={showNotification}
                    id={id}
                    isViewOnly={true}
                  />
                )
              }
            ]}
          />
        </Grid>
      </Grid>

      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          serverDataRef.current = data
          handleSubmitIsf5()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
    </>
  )
}

export default EditPage

function ButtonSection(props) {
  const { history, loading, onSubmitCargo } = props
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { formState: { isSubmitting }, getValues, trigger } = useFormContext()
  const { getTranslatedText } = useContext(AceIsfTranslationContext)
  const editTemplate = getValues() ? getValues().isEditTemplate : false;

  return (
    <>
      <Grid container justify='space-between'>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting || loading}
            onClick={() => setConfirmDialog(true)}
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                type='submit'
                color='primary'
                disabled={isSubmitting || loading}
              >
                {getTranslatedText('ACE_ISF', 'updateButton')}
              </CngButton>
            </Grid>
            {!editTemplate && <>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  disabled={isSubmitting || loading}
                  shouldShowProgress={loading}
                  onClick={async () => {
                    let result = await trigger()
                    if (result) {
                      onSubmitCargo(getValues())
                    }
                  }}
                >
                  {getTranslatedText('ACE_ISF', 'submitButton')}
                </CngButton>
              </Grid>
            </>
            }
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACEISF5_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}