import React, { useEffect, useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import CngSection from 'src/components/cngcomponents/CngSection';
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayKeys from 'src/constants/locale/key/AciHighwayResponse'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import Response from './Response'
import StatusNotification from './StatusNotification'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'

const {
  filter: { EQUAL }
} = constants

function ResponseMessage({ showNotification, id, isViewOnly = false }) {
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_RESPONSE)
  const { fetchRecords, securedSendRequest } = useServices()
  const { getTranslatedText } = useContext(AceIsfTranslationContext)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('ACE_ISF', 'response')}>
          <Response
            showNotification={showNotification}
            id={id}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('ACE_ISF', 'statusNotification')}>
          <StatusNotification
            showNotification={showNotification}
            id={id}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default ResponseMessage;