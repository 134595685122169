import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useTranslation, useServices, components } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import StatusBar from '../../../components/aciacehighway/StatusBar'
// import CargoCommodityTable from './CargoCommodityTable'
// import CargoCommodityDialog from './CargoCommodityDialog'
import moment from 'moment'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import History from './History'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import ShipmentEntry from './ShipmentEntry'
import PartyTable from './PartyTable'
import ContainerTable from './ContainerTable'

const {
  button: { CngButton },
  form: {
    field: {
      CngSelectField
    },
  },
  CngGridItem,
} = components

function Isf10ViewContent(props) {
  const { data, getLookupValue, getCountryStateLabel } = props
  const { fetchRecords, securedSendRequest } = useServices()
  const [cargoCommodityDialog, setCargoCommodityDialog] = useState({
    open: false,
    cargoCommodity: null
  })
  const { getTranslatedText } = useContext(AceIsfTranslationContext)
  const theme = useTheme()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusBar status={data.status} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='space-between' spacing={3}>
            <Grid item xs={12} md='auto'>
              <Typography variant='body2'>
                The following information was updated on{' '}
                <strong>
                  {moment(data.updatedDate).format('dddd, D MMM YYYY, HH:mm')}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={getTranslatedText('ACE_ISF', 'title')}>
            <CngSubSection title={getTranslatedText('ACE_ISF', 'myReference')}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={getTranslatedText('ACE_ISF', 'refBookingNo')}>
                    {data.refBookingNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={getTranslatedText('ACE_ISF', 'portOfLading')}>
                    {data.portOfLading}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={getTranslatedText('ACE_ISF', 'estimateLoadingDate')}>
                    {data.estimateLoadingDate}
                  </CngField>
                </Grid>
              </Grid>
            </CngSubSection>
            &nbsp;
            <CngSubSection title={getTranslatedText('ACE_ISF', 'isfShipment')}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={getTranslatedText('ACE_ISF', 'cbpTransactionNoInfo')}>
                    {data.cbpTransactionNo}
                  </CngField>
                </Grid>

                <Grid item xs={12}>
                  <CngSection title={getTranslatedText('ACE_ISF', 'shipmentReference')}>
                    {data.shipments.length > 0 ? (
                      <Grid container spacing={2}>
                        {data.shipments.map(shipment => (
                          <Grid key={shipment.id || shipment._id} item xs={12}>
                            <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                              <Box padding={2}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={4}>
                                    {/* <CngField label={getTranslatedText('ACE_ISF', 'blType')}>
                                      {shipment.blType}
                                    </CngField> */}
                                    <CngField label={getTranslatedText('ACE_ISF', 'blType')}>
                                      {getLookupValue('blTypes', shipment.blType)}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <CngField label={getTranslatedText('ACE_ISF', 'blNo')}>
                                      {shipment.blNo}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <CngField label={getTranslatedText('ACE_ISF', 'blMatched')}>
                                      {shipment.blMatched}
                                    </CngField>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography variant='body2' color='textSecondary'>
                        No records to display
                      </Typography>
                    )}
                  </CngSection>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'shipmentType')}>
                    {getLookupValue('shipmentTypes', data.shipmentType)}
                  </CngField>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'modeOfTransport')}>
                    {getLookupValue('modeOfTransports', data.modeOfTransport)}
                  </CngField>
                </Grid>

                {data.shipmentType === '06' &&
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={getTranslatedText('ACE_ISF', 'carnetNumber')}>
                        {data.carnetNumber}
                      </CngField>
                    </Grid>
                  </>
                }

                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'entryNo')}>
                    {data.entryNo}
                  </CngField>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'masterBL')}>
                    {data.masterBL}
                  </CngField>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'scac')}>
                    {data.scac}
                  </CngField>
                </Grid>
              </Grid>

              {data.shipmentType === '11' &&
                <>
                  <Grid item xs={12}>
                    &nbsp;
                    <Grid alignItems='center' container spacing={2}>
                      <CngGridItem xs={12} sm='auto'>
                        <Typography variant='body2'>
                          Shipment info
                        </Typography>
                      </CngGridItem>
                      <CngGridItem xs={12} sm><Divider /></CngGridItem>
                    </Grid>
                    &nbsp;
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('ACE_ISF', 'shipmentSubType')}>
                          {getLookupValue('shipmentSubTypes', data.shipmentSubType)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('ACE_ISF', 'estimatedValue')}>
                          {data.estimatedValue}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('ACE_ISF', 'estimatedQuantity')}>
                          {data.estimatedQuantity}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('ACE_ISF', 'unitOfMeasure')}>
                          {getLookupValue('unitOfMeasures', data.unitOfMeasure)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('ACE_ISF', 'estimatedWeight')}>
                          {data.estimatedWeight}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('ACE_ISF', 'weightQualifiers')}>
                          {getLookupValue('weightQualifiers', data.weightQualifier)}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            </CngSubSection>
            &nbsp;
            <CngSubSection title={getTranslatedText('ACE_ISF', 'filerReference')}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'importerDocType')}>
                    {getLookupValue('importerDocTypes', data.importerDocType)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'importerRefNo')}>
                    {data.importerRefNo}
                  </CngField>
                </Grid>
                {data.importerDocType === 'AEF' &&
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={getTranslatedText('ACE_ISF', 'dateOfBirth')}>
                        {data.dateOfBirth}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={getTranslatedText('ACE_ISF', 'name')}>
                        {data.name}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={getTranslatedText('ACE_ISF', 'countryIssuance')}>
                        {data.countryIssuance}
                      </CngField>
                    </Grid>
                  </>
                }

                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'cbpBond')}>
                    {data.cbpBond}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'bondType')}>
                    {getLookupValue('bondTypes', data.bondType)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'bondHolderDocType')}>
                    {getLookupValue('bondHolderDocTypes', data.bondHolderDocType)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'bondHolderDocNo')}>
                    {data.bondHolderDocNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'bondTerm')}>
                    {getLookupValue('bondTerms', data.bondTerm)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('ACE_ISF', 'agentCode')}>
                    {data.agentCode}
                  </CngField>
                </Grid>
              </Grid>
            </CngSubSection>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={getTranslatedText('ACE_ISF', 'commercialParty')}>
            <PartyTable
              data={data.parties}
              isView={true}
            />
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={getTranslatedText('ACE_ISF', 'containerInfo')}>
            <ContainerTable
              data={data.containers}
            />
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title="History">
            <History isf10Id={data.id} />
          </CngSection>
        </Grid>
      </Grid>
    </>
  )
}

export default Isf10ViewContent
