import makeValidationSchema from './PartyMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext, useMemo } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import { Box, IconButton, Card, CardContent, Grid, Divider, Typography, InputAdornment } from '@material-ui/core'
import { components, useServices, constants } from 'cng-web-lib'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import { useFormContext, useWatch } from 'react-hook-form';
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import CngSection from '../../../components/cngcomponents/CngSection'
import HtsEntry from './HtsEntry'
import HtsFormProperties from './HtsFormProperties'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import PartyMasterApiUrls from '../../../apiUrls/PartyMasterApiUrls'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const {
  filter: { LIKE, EQUAL, IN, NOT_EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _partyId: 0,
  id: "",
  headerId: "",
  partyType: "",
  docType: "",
  docNumber: "",
  partyName: "",
  secondaryInfoType: "",
  secondaryInfo: "",
  address: "",
  city: "",
  country: "",
  province: "",
  postalCode: "",
  version: 0,
  partyIdentification: "",
  savePartyFlag: false,
  partyDropDown: "",
  dateOfBirth: "",
  countryIssuance: "",
  name: "",
  htsList: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  shipmentType
}) {
  const { translate } = useTranslation(Namespace.ISF10)
  const { getTranslatedText } = useContext(AceIsfTranslationContext)
  const { getValues, setValue, reset, trigger } = useFormContext();
  const country = useWatch({ name: 'country' });
  const province = useWatch({ name: 'province' });
  const partyType = useWatch({ name: 'partyType' });
  const countryIssuance = useWatch({ name: 'countryIssuance' });
  const savePartyFlag = useWatch({ name: 'savePartyFlag' });
  const docType = useWatch({ name: 'docType' });
  const docNumber = useWatch({ name: 'docNumber' });
  const { _id: _htsId, htsList } = useWatch();
  const [templateDialog, setTemplateDialog] = useState(false)
  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    if (partyType === 'MF' && htsList.length === 0) {
      const { initialValues } = HtsFormProperties.formikProps
      setValue('htsList', [{ ...initialValues, _htsId, _id: 1 }])
    }
  }, [partyType])

  function handleAddHts(data) {
    const htsDTO = [...getValues('htsList'), data].map(
      (document, index) => ({ ...document, _htsId, _id: index + 1 })
    )

    setValue('htsList', htsDTO, { shouldDirty: true })
  }

  function handleDeleteHts(data) {
    const htsDTO = [...getValues('htsList')].filter((document) =>
      document.id ? document.id !== data.id : document._id !== data._id
    )

    setValue('htsList', htsDTO, { shouldDirty: true })
  }

  function handleApplyTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      partytype,
      partyname1,
      address1,
      cityname,
      postalcode,
      countrycode,
      statecode,
      contactperson,
      telephonenumber,
      faxNo,
      email,
      partyIdentifier,
      secondaryInfoType,
      secondaryInfo
    } = template

    setValue('partyType', partytype, { shouldDirty: true })
    if (partyType !== "CN" || partyType !== "IM") {
      setValue('partyName', partyname1 || '', { shouldDirty: true })
      setValue('address', address1 || '', { shouldDirty: true })
      setValue('city', cityname || '', { shouldDirty: true })
      setValue('postalCode', postalcode || '', { shouldDirty: true })
      setValue('country', countrycode || '', { shouldDirty: true })
      setValue('province', statecode || '', { shouldDirty: true })
    }

    if (partyIdentifier != null && partyIdentifier != undefined && partyIdentifier != "") {
      for (var i = 0, l = partyIdentifier.length; i < l; i++) {
        var obj = partyIdentifier[i];
        setValue('docType', obj.partyIdentifierCode || '', { shouldDirty: true })
        setValue('docNumber', obj.partyIdentifierNumber || '', { shouldDirty: true })
      }
    }

    setValue('secondaryInfoType', secondaryInfoType || '', { shouldDirty: true })
    setValue('secondaryInfo', secondaryInfo || '', { shouldDirty: true })
    trigger()
  }

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'partyidn',
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'partyIdentification')
    },
    {
      field: 'partytype',
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'partyType'),
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partytype)}
        </Typography>
    },
    {
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'partyName'),
      render: data => [data.partyname1, data.partyname2].join(' ')
    },
    {
      field: 'statecode',
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'stateCode')
    },
    {
      field: 'countrycode',
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'countryCode')
    },
  ]

  return (
    <>
      <Grid container spacing={2}>
        <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.partyType}>
          <CngCodeMasterAutocompleteField
            required
            name='partyType'
            label={getTranslatedText('ACE_ISF', 'partyType')}
            codeType='ACEISF_PARTY_TYPE'
            size='small'
            lookupProps={{ filters: [{ field: 'code', operator: NOT_EQUAL, value: "IIM" }] }}
          />
        </CngGridItem>
        <CngGridItem xs={12} lg={8}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.savePartyFlag}>
              <CngCheckboxField
                label={
                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                    {getTranslatedText('ACE_ISF', 'saveToPartyMaster')}
                  </Typography>
                }
                name='savePartyFlag'
                disabled={disabled}
                onChange={(e) => {
                  setValue('savePartyFlag', e.target.checked)

                  if (!e.target.checked) {
                    setValue('partyIdentification', "")
                  }
                }}
                size='small'
                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm shouldHide={shouldHideMap?.partyIdentification}>
              <CngTextField
                required
                name='partyIdentification'
                inputProps={{
                  style: { textTransform: 'uppercase' },
                  maxLength: 35
                }}
                label={getTranslatedText('ACE_ISF', 'partyId')}
                disabled={disabled || !savePartyFlag}
                onBlur={(e) => {
                  setValue('partyIdentification', e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4}>
              <NaAcePartyMasterAutoCompleteField
                name='partyDropDown'
                label={getTranslatedText('ACE_ISF', 'partyIdLookup')}
                disabled={disabled}
                onChange={(_, options) => handleApplyTemplate(options.data)}
                lookupProps={{
                  filters: [{
                    field: 'partyId',
                    operator: EQUAL,
                    value: FileForUserGetPartyId()
                  },
                  { field: 'partytype', operator: IN, value: ((partyType === 'CN' || partyType === 'SH') ? ['CN', 'OS', 'CZ', 'SH'] : partyType) } //CZ, OS is Shipper in other module
                  ]
                }}
                size='small'
                fullWidth
                disableClearable
                textFieldProps={{
                  InputProps: {
                    customEndAdornment: () => (
                      <InputAdornment position='end' style={{ marginTop: -16 }}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation()
                          setTemplateDialog(true)
                        }}>
                          <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }}
                forcePopupIcon={false}
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>
      </Grid>
      &nbsp;
      <CngSection title="Document Details">
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.docType}>
            <CngCodeMasterAutocompleteField
              required={(partyType === 'IM' || partyType === 'CN')}
              name='docType'
              label={getTranslatedText('ACE_ISF', 'docType')}
              codeType={(partyType === 'IM' || partyType === 'CN') ? 'ACEISF_IM_CN_DOC_TYPE' : 'ACEISF_DOC_TYPE'}
              size='small'
              key={partyType}
              filterOptions={(options, { inputValue }) => {
                console.log("shipmentType: " + shipmentType);
                console.log("inputValue: " + inputValue);
                if (shipmentType !== "03") {
                  options = options.filter(option => option.value !== "AEF");
                }
                // Filter remaining options based on inputValue
                return options.filter(option =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                );
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.docNumber}>
            <CngTextField
              required={(partyType === 'IM' || partyType === 'CN' || docType)}
              name='docNumber'
              label={getTranslatedText('ACE_ISF', 'docNumber')}
              disabled={disabled}
              inputProps={{ maxLength: (docType === 'EI' ? 12 : docType === '34' ? 11 : docType === 'ANI' ? 12 : docType === 'CIN' ? 12 : docType === 'DUN' ? 9 : docType === 'DNS' ? 13 : 20) }}
              onChange={(e) => {
                let word = e.target.value.toUpperCase()
                let word2 = word.substring(4, 11)

                if (docType === 'EI' && word.length === 3 && !word.includes('-')) {
                  word = word.substring(0, 2) + '-' + word.substring(2, 10)
                } else if (docType === '34' && word.length === 4 && !word.includes('-')) {
                  word = word.substring(0, 3) + '-' + word.substring(3, 6)
                } else if (docType === '34' && word2.length === 3 && !word2.includes('-')) {
                  word = word.substring(0, 6) + '-' + word.substring(6, 10)
                } else if (docType === 'ANI' && word.length === 7 && !word.includes('-')) {
                  word = word.substring(0, 6) + '-' + word.substring(6, 11)
                } else if (docType === 'CIN' && word.length === 0 && !word.includes('-')) {
                  word = word.substring(0, 1) + '-' + word.substring(1, 11)
                }

                setValue('docNumber', word)
              }}
              placeholder={docType === 'EI' ? 'NN-NNNNNNNXX' : docType === '34' ? 'NNN-NN-NNNN' : docType === 'ANI' ? 'YYDDPP-NNNNN' : docType === 'CIN' ? '-CCCCCCCCCCC' : docType === 'DUN' ? 'NNNNNNNNN' : docType === 'DNS' ? 'NNNNNNNNNNNNN' : ''}
              size='small'
            />
          </CngGridItem>

          {docType === 'AEF' &&
            <>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.dateOfBirth}>
                <CngDateField
                  required
                  disabled={disabled}
                  name='dateOfBirth'
                  label={getTranslatedText('ACE_ISF', 'dateOfBirth')}
                  format='YYYY-MM-DD'
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.name}>
                <CngTextField
                  required
                  name='name'
                  label={getTranslatedText('ACE_ISF', 'name')}
                  disabled={disabled}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setValue('name', e.target.value.toUpperCase())
                  }}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.countryIssuance}>
                <CngCountryAutocompleteField
                  required
                  name='countryIssuance'
                  label={getTranslatedText('ACE_ISF', 'countryIssuance')}
                  disabled={disabled}
                  key={countryIssuance}
                  onChange={() => {
                    setValue('province', '')
                  }}
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                  size='small'
                />
              </CngGridItem>
            </>}
        </Grid>
      </CngSection>
      {(partyType !== 'CN' && partyType !== 'IM') && <>
        &nbsp;
        <Grid item xs={12}>
          <Grid alignItems='center' container spacing={2}>
            <CngGridItem xs={12} sm='auto'>
              <Typography variant='body2'>
                OR
              </Typography>
            </CngGridItem>
            <CngGridItem xs={12} sm><Divider /></CngGridItem>
          </Grid>
        </Grid>
        &nbsp;
        <CngSection title="Party Details">
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.partyName}>
              <CngTextField
                required={!docType && !docNumber}
                name='partyName'
                label={getTranslatedText('ACE_ISF', 'partyName')}
                disabled={disabled}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => {
                  setValue('partyName', e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.secondaryInfoType}>
              <CngCodeMasterAutocompleteField
                name='secondaryInfoType'
                label={getTranslatedText('ACE_ISF', 'secondaryInfoType')}
                codeType='ACEISF_SECONDARY_INFO_TYPE'
                size='small'
                key={getValues("secondaryInfoType")}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.secondaryInfo}>
              <CngTextField
                name='secondaryInfo'
                label={getTranslatedText('ACE_ISF', 'secondaryInfo')}
                disabled={disabled}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => {
                  setValue('secondaryInfo', e.target.value.toUpperCase())
                }}
                size='small'
                InputLabelProps={{ shrink: getValues('secondaryInfo') }}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.address}>
              <CngTextField
                required={!docType && !docNumber}
                name='address'
                label={getTranslatedText('ACE_ISF', 'address')}
                disabled={disabled}
                inputProps={{ maxLength: 105 }}
                onChange={(e) => {
                  setValue('address', e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.city}>
              <CngTextField
                required={!docType && !docNumber}
                name='city'
                label={getTranslatedText('ACE_ISF', 'city')}
                disabled={disabled}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => {
                  setValue('city', e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.country}>
              <CngCountryAutocompleteField
                required={!docType && !docNumber}
                name='country'
                label={getTranslatedText('ACE_ISF', 'country')}
                disabled={disabled}
                key={country}
                onChange={() => {
                  setValue('province', '')
                }}
                lookupProps={{
                  label: (record) => `${record.code};${record.descriptionEn}`
                }}
                size='small'
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.province}>
              <NaStateCodeAutoCompleteField
                name='province'
                label={getTranslatedText('ACE_ISF', 'province')}
                size='small'
                countryCode={country}
                key={country + province}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.postalCode}>
              <CngTextField
                name='postalCode'
                label={getTranslatedText('ACE_ISF', 'postalCode')}
                disabled={disabled}
                inputProps={{ maxLength: 15 }}
                onChange={(e) => {
                  setValue('postalCode', e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </CngSection>
        &nbsp;
      </>}

      {partyType === 'MF' &&
        <CngSection title={getTranslatedText('ACE_ISF', 'hts')}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {htsList.map((hts, index) => (
                  <React.Fragment key={hts._id || hts.id || index}>
                    <Grid item xs={12}>
                      <HtsEntry
                        index={index}
                        isLast={htsList.length === 1}
                        onAddShipment={handleAddHts}
                        onDeleteShipment={handleDeleteHts}
                        shipment={hts}
                        showNotification={showNotification}
                        shouldHideMap={shouldHideMap}
                      />
                    </Grid>
                    {htsList.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CngSection>
      }

      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partytype', operator: IN, value: partyType },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'partyidn', operator: LIKE }}
        // sortOptions={sortOptions}
        title='Party Template'
      />
    </>
  )
}

const PartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PartyFormProperties
