import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let regexContainerNo = "^[A-Z]{4}[a-zA-Z0-9]+$"

  return Yup.object({
    containerNo: Yup.string().required(requiredMessage).nullable().matches(regexContainerNo, "Incorrect Container Number format: CCCCXXXXXXXXXXXXXXX"),
    descriptionCode: Yup.string().required(requiredMessage).nullable(),
    containerInitial: Yup.string().nullable(),
    sizeTypeCode: Yup.string().nullable(),
  })
}

export default makeValidationSchema
