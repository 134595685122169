import React, { useEffect, useState, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import PartyDialog from './PartyDialog'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Chip,
  Tooltip
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import { useFormContext, useWatch } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  form: {
    field: {
      CngCodeMasterAutocompleteField,
      CngSelectField,
    },
  },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, NOT_EQUAL }
} = constants

function PartyTable(props) {
  const {
    data,
    onAddParty,
    onDeleteParty,
    onEditParty,
    showNotification,
    isView,
  } = props

  const formContext = useFormContext();
  let importerDocType = formContext !== null ? formContext.getValues('importerDocType') : "";
  let importerRefNo = formContext !== null ? formContext.getValues('importerRefNo') : "";
  let shipmentType = formContext !== null ? formContext.getValues('shipmentType') : "";

  const [lookups, setLookups] = useState(null)
  const [partyTypes, setPartyTypes] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [partyDTODialog, setPartyDTODialog] = useState({
    isView: false,
    open: false,
    partyDTO: null
  })
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const classes = useStyles()
  const { getTranslatedText } = useContext(AceIsfTranslationContext)
  

  const filtered =
    searchTerm !== ''
      ? data.filter((partyDTO) =>
        getLookupValue('partyType', partyDTO.partyType)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      : data

  useEffect(() => {
    Promise.all([
      // Party Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      // Document Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_DOC_TYPE' }],
        undefined,
        'code'
      ),
      // Document Type For IM and CN
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_IM_CN_DOC_TYPE' }],
        undefined,
        'code'
      ),
    ]).then(
      ([
        partyType,
        docType,
        docTypeIMCN
      ]) => {
        setPartyTypes(Object.entries(partyType).map(([value, text]) => ({ text, value })));
        setLookups({
          partyType,
          docType,
          docTypeIMCN
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  const handleOnChange = (selectedValue, selectedParty) => {
    console.log("selectedValue: " + JSON.stringify(selectedValue))
    const matchedItem = filtered.find(item => item.partyType === selectedValue);
    if (matchedItem) {
      console.log("matchedItem: " + JSON.stringify(matchedItem))
      if (matchedItem.docType === "" && matchedItem.docNumber === "" && matchedItem.partyName === "") {
        if (window.confirm('Please select Party which has records.')) {
          return;
        }
      }
      selectedParty.docType = matchedItem.docType;
      selectedParty.docNumber = matchedItem.docNumber;
      if (selectedParty && (selectedParty.docType !== "IM" && selectedParty.docType !== "CN")) {
        selectedParty.partyName = matchedItem.partyName;
        selectedParty.secondaryInfoType = matchedItem.secondaryInfoType;
        selectedParty.secondaryInfo = matchedItem.secondaryInfo;
        selectedParty.address = matchedItem.address;
        selectedParty.city = matchedItem.city;
        selectedParty.country = matchedItem.country;
        selectedParty.province = matchedItem.province;
        selectedParty.postalCode = matchedItem.postalCode;
        selectedParty.partyIdentification = matchedItem.partyIdentification;
        selectedParty.partyDropDown = matchedItem.partyDropDown;
      }
      if (selectedParty && selectedParty.docType === "MF") {
        selectedParty.htsList = matchedItem.htsList;
      }
    } else {
      if (selectedValue === "IIM" && importerDocType !== "" && importerRefNo !== "") {
        selectedParty.docType = importerDocType;
        selectedParty.docNumber = importerRefNo;
      } else {
        if (selectedValue === "IIM" && importerDocType === "" && importerRefNo === "") {
          if (window.confirm('Please select the document type and documnet number in Filer Refrence.')) {
            return;
          }
        } else {
          console.log('No matching data found for key:', selectedValue);
          if (window.confirm('Please select Party which has records.')) {
            return;
          }
        }
      }
    }
  };

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddParty && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={() =>
                  setPartyDTODialog({
                    open: true,
                    isView: false,
                    partyDTO: null
                  })
                }
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Party
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{getTranslatedText('ACE_ISF', 'partyType')}</TableCell>
              <TableCell>{getTranslatedText('ACE_ISF', 'documentDetails')}</TableCell>
              <TableCell>{getTranslatedText('ACE_ISF', 'partyDetails')}</TableCell>
              <TableCell>{getTranslatedText('ACE_ISF', 'manufacturerHtsDetails')}</TableCell>
              {!isView && <TableCell style={{ width: '200px' }}>{getTranslatedText('ACE_ISF', 'sameAs')}</TableCell>}
              {(onEditParty || onDeleteParty) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((partyDTO, index) => (
                <TableRow
                  key={Math.random()}
                  onClick={() =>
                    isView && setPartyDTODialog({ open: true, isView: true, partyDTO })
                  }
                >
                  <TableCell>{getLookupValue('partyType', partyDTO.partyType)}</TableCell>
                  <TableCell>{(partyDTO.docType && partyDTO.docNumber) && <Chip label={getLookupValue((partyDTO.partyType === 'IM' || partyDTO.partyType === 'CN') ? 'docTypeIMCN' : 'docType', partyDTO.docType) + " | " + partyDTO.docNumber} />}</TableCell>
                  <TableCell>{(partyDTO.partyName && partyDTO.address) && <Chip label={partyDTO.partyName + " | " + partyDTO.address} />}</TableCell>
                  <TableCell>{partyDTO.htsList ? partyDTO.htsList.map(function (htsDTO, i) {
                    return <Chip
                      label={[htsDTO.htsNo, htsDTO.countryOrigin].join(' | ')}
                      size='small'
                    />;
                  }) : ""}
                  </TableCell>
                  {!isView && <TableCell><Grid>
                    <CngCodeMasterAutocompleteField
                      key={index}
                      name={`sameAs-${index}`}
                      label={getTranslatedText('ACE_ISF', 'sameAs')}
                      codeType='ACEISF_PARTY_TYPE'
                      onChange={(selectedValue) => handleOnChange(selectedValue, partyDTO)}
                      lookupProps={{ filters: [{ field: 'code', operator: NOT_EQUAL, value: partyDTO.partyType }] }}
                      filterOptions={(options, { inputValue }) => {
                        if (partyDTO.partyType !== "IM") {
                          options = options.filter(option => option.value !== "IIM");
                        }
                        // Filter remaining options based on inputValue
                        return options.filter(option =>
                          option.label.toLowerCase().includes(inputValue.toLowerCase())
                        );
                      }}
                    />
                  </Grid></TableCell>}
                  {(onEditParty || onDeleteParty) && (
                    <TableCell align='right'>
                      <Grid container justify='flex-end' spacing={1}>
                        {onEditParty && (
                          <Grid item xs='auto'>
                            <Tooltip placement='bottom' title={partyDTO.docNumber === '' && partyDTO.partyName === '' ? 'Add' : 'Edit'}>
                              <span>
                                <CngIconButton
                                  type='outlined'
                                  // icon={['fal', 'pen']}
                                  icon={partyDTO.docNumber === '' && partyDTO.partyName === '' ? ['fal', 'plus'] : ['fal', 'pen']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    setPartyDTODialog({ open: true, isView: false, partyDTO })
                                  }}
                                  size='small'
                                />
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                        {onDeleteParty && (
                          <Grid item xs='auto'>
                            <Tooltip placement='bottom' title='Delete'>
                              <span>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'trash']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    onDeleteParty(partyDTO)
                                  }}
                                  size='small'
                                />
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item xs='auto'>
                          <Tooltip placement='bottom' title='Clone'>
                            <span>
                              <CngIconButton
                                icon={['fal', 'copy']}
                                onClick={() => onAddParty({ ...partyDTO, id: undefined })}
                                size='small'
                                type='outlined'
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditParty || onDeleteParty ? 1 : 0)}
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer >
      <PartyDialog
        getLookupValue={getLookupValue}
        getCountryStateLabel={getCountryStateLabel}
        isView={partyDTODialog.isView}
        open={partyDTODialog.open}
        onClose={() =>
          setPartyDTODialog({ open: false, isView: false, partyDTO: null })
        }
        onAddPartyDTO={onAddParty}
        onEditPartyDTO={onEditParty}
        showNotification={showNotification}
        partyDTO={partyDTODialog.partyDTO}
        shipmentType={shipmentType}
      />
    </>
  )
}

export default PartyTable
