import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let regexEI = "^\\d{2}\\-\\d{7}\\w{2}$"
  let regex34 = "^\\d{3}\\-\\d{2}\\-\\d{4}$"
  let regexANI = "^\\d{6}\\-\\d{5}$"
  let regexCIN = "^\\-\\w{11}$"
  let regexDUN = "^\\d{9}$"
  let regexDNS = "^\\d{13}$"

  const regexAlphaNumericSpecChar = "^[a-zA-Z0-9!\"\',-./:;?=()_@./#&+\-,\\s]*$"

  return Yup.object({
    partyType: Yup.string().nullable(),
    docType: Yup.string().nullable(),
    docNumber: Yup.string().when('docType', {
      is: (docType) => (docType === 'EI'),
      then: Yup.string().matches(/^[a-zA-Z0-9-\-]+$/, "Please enter alphanumeric values and allowed charter is -").required(requiredMessage).nullable().matches(regexEI, "Incorrect Document Number format: NN-NNNNNNNXX")
    })
      .when('docType', {
        is: (docType) => (docType === '34'),
        then: Yup.string().matches(/^[a-zA-Z0-9-\-]+$/, "Please enter alphanumeric values and allowed charter is -").required(requiredMessage).nullable().matches(regex34, "Incorrect Document Number format: NNN-NN-NNNN")
      })
      .when('docType', {
        is: (docType) => (docType === 'ANI'),
        then: Yup.string().matches(/^[a-zA-Z0-9-\-]+$/, "Please enter alphanumeric values and allowed charter is -").required(requiredMessage).nullable().matches(regexANI, "Incorrect Document Number format: YYDDPP-NNNNN")
      })
      .when('docType', {
        is: (docType) => (docType === 'CIN'),
        then: Yup.string().matches(/^[a-zA-Z0-9-\-]+$/, "Please enter alphanumeric values and allowed charter is -").required(requiredMessage).nullable().matches(regexCIN, "Incorrect Document Number format: -CCCCCCCCCCC")
      })
      .when('docType', {
        is: (docType) => (docType === 'DUN'),
        then: Yup.string().matches(/^[a-zA-Z0-9\-]+$/, "Please enter only alphanumeric(without spaces)").required(requiredMessage).nullable().matches(regexDUN, "Incorrect Document Number format: NNNNNNNNN")
      })
      .when('docType', {
        is: (docType) => (docType === 'DNS'),
        then: Yup.string().matches(/^[a-zA-Z0-9\-]+$/, "Please enter only alphanumeric(without spaces)").required(requiredMessage).nullable().matches(regexDNS, "Incorrect Document Number format: NNNNNNNNNNNNN")
      })
      .nullable(),
    partyName: Yup.string().when('docType', {
      is: (docType) => (!docType),
      then: Yup.string().required(requiredMessage).nullable()
    }).nullable(),
    secondaryInfoType: Yup.string().nullable(),
    secondaryInfo: Yup.string().matches(regexAlphaNumericSpecChar, "Please enter alphanumeric values and allowed charter is ,-./:;?=()_@./#&+").nullable(),
    address: Yup.string().when('docType', {
      is: (docType) => (!docType),
      then: Yup.string().matches(regexAlphaNumericSpecChar, "Please enter alphanumeric values and allowed charter is ,-./:;?=()_@./#&+").required(requiredMessage).nullable()
    }).nullable(),
    city: Yup.string().when('docType', {
      is: (docType) => (!docType),
      then: Yup.string().matches(regexAlphaNumericSpecChar, "Please enter alphanumeric values and allowed charter is ,-./:;?=()_@./#&+").required(requiredMessage).nullable()
    }).nullable(),
    country: Yup.string().when('docType', {
      is: (docType) => (!docType),
      then: Yup.string().required(requiredMessage).nullable()
    }).nullable(),
    province: Yup.string().nullable(),
    postalCode: Yup.string().matches(regexAlphaNumericSpecChar, "Please enter alphanumeric values and allowed charter is ,-./:;?=()_@./#&+").nullable(),
  })
}

export default makeValidationSchema
