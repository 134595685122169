import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import pathMap from 'src/paths/pathMap'
import Isf10ViewContent from './Isf10ViewContent'
import ISF10ApiUrls from 'src/apiUrls/ISF10ApiUrls'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function Isf10ViewPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const { fetchRecord, fetchRecords, securedSendRequest } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        ISF10ApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Bl Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_BL_TYPE' }],
        undefined,
        'code'
      ),
      // Shipment Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_SHIPMENT_TYPE' }],
        undefined,
        'code'
      ),
      // Mode of Transport
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_MODE_OF_TRANSPORT' }],
        undefined,
        'code'
      ),
      // Shipment Sub Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_SHIP_SUB_TYPE' }],
        undefined,
        'code'
      ),
      // Unit of Measure
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_UOM_CODE' }],
        undefined,
        'code'
      ),
      // Weight Qualifiers
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_WEIGHT_QUALIFIERS' }],
        undefined,
        'code'
      ),
      // Importer Document Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_IMP_HOL_DOC_TYPE' }],
        undefined,
        'code'
      ),
      // Bond Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_BOND_TYPE' }],
        undefined,
        'code'
      ),
      // Bond Holder Document Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_IMP_HOL_DOC_TYPE' }],
        undefined,
        'code'
      ),
      // Bond Term
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEISF_BOND_TERM' }],
        undefined,
        'code'
      ),
    ]).then(
      ([
        data,
        blTypes,
        shipmentTypes,
        modeOfTransports,
        shipmentSubTypes,
        unitOfMeasures,
        weightQualifiers,
        importerDocTypes,
        bondTypes,
        bondHolderDocTypes,
        bondTerms

      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.ACEISF10_LIST_VIEW)
        }
        setData(data)
        setLookups({
          blTypes,
          shipmentTypes,
          modeOfTransports,
          shipmentSubTypes,
          unitOfMeasures,
          weightQualifiers,
          importerDocTypes,
          bondTypes,
          bondHolderDocTypes,
          bondTerms
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  if (data === null) {
    return <CircularProgress />
  }

  return (
    <Isf10ViewContent
      data={data}
      getLookupValue={getLookupValue}
      getCountryStateLabel={getCountryStateLabel}
    />
  )
}

export default Isf10ViewPage
