import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices } from 'cng-web-lib'
import { Grid, Divider, Typography } from '@material-ui/core'
import { AceIsfTranslationContext } from '../../contexts/ACEISFTranslationContext'
import { NaCustomsOfficeAutocompleteField, NaSublocationWithoutCodeAutocompleteField } from 'src/components/na'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import Checkbox from 'src/components/Checkbox'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import { useLocation } from 'react-router-dom'
import TemplateSelectDialogWithOptionACIHWYCargo from 'src/components/aciacehighway/TemplateSelectDialogWithOptionACIHWYCargo'
import CngSection from '../../../../components/cngcomponents/CngSection'
import RadioButtonGroup from '../../../../components/aciacehighway/RadioButtonGroup'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from 'src/components/Switch'
import ShipmentFormProperties from '../ShipmentFormProperties'
import ShipmentEntry from '../ShipmentEntry'
import HtsTable from '../HtsTable'

const {
    button: { CngButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
            CngSelectField
        },
    },
    CngGridItem,
} = components

function HtsDetailsSection(props) {
    const { disabled, shouldHideMap, user, blTypes, showNotification, shipments } = props
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const { getTranslatedText } = useContext(AceIsfTranslationContext)
    const location = useLocation();
    const { htsList, scac } = useWatch()

    function handleAddHts(data) {
        const trailerDTO = [...getValues('htsList'), data].map(
            (trailerDTO, index) => ({ ...trailerDTO, _id: index + 1 }))

        setValue('htsList', trailerDTO, { shouldValidate: true })
    }

    function handleDeleteHts(data) {
        const trailerDTO = [...getValues('htsList')].filter(
            (trailerDTO) => trailerDTO.id ? trailerDTO.id !== data.id : trailerDTO._id !== data._id
        )

        setValue('htsList', trailerDTO, { shouldValidate: true })
    }

    function handleEditHts(data) {
        const trailerDTO = [...getValues('htsList')]
        const index = trailerDTO.findIndex(
            (trailerDTO) => trailerDTO.id ? trailerDTO.id === data.id : trailerDTO._id === data._id
        )

        trailerDTO[index] = data

        setValue('htsList', trailerDTO, { shouldValidate: true })
    }

    return (
        <HtsTable
            data={htsList}
            onAddHts={handleAddHts}
            onDeleteHts={handleDeleteHts}
            onEditHts={handleEditHts}
            showNotification={showNotification}
            shouldHideMap={shouldHideMap}
            disabled={disabled}
        />
    )
}

export default HtsDetailsSection