import React, { useEffect, useState, useContext, useMemo } from 'react'
import Namespace from 'src/constants/locale/Namespace'

import { Grid, Card, CardContent } from '@material-ui/core'
import { components, useServices, constants } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import ShipmentFormProperties from './ShipmentFormProperties'
import PartyFormProperties from './PartyFormProperties'
import HtsFormProperties from './HtsFormProperties'
import ContainerFormProperties from './ContainerFormProperties'
import TransactionFormProperties from './TransactionFormProperties'
import ResponseFormProperties from './ResponseFormProperties'
import HeaderDetailsSection from './sections/HeaderDetailsSection'
import PartiesDetailsSection from './sections/PartiesDetailsSection'
import DeliveryLocationSection from './sections/DeliveryLocationSection'
import HtsDetailsSection from './sections/HtsDetailsSection'
import ContainerDetailsSection from './sections/ContainerDetailsSection'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { useFormContext, useWatch } from "react-hook-form";
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import CngSection from 'src/components/cngcomponents/CngSection'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField
    },
  },
  table: {
    CngLocalModeDialogFormTable,
    DateRangeFilter: CngDateRangeFilter,
    useFetchCodeMaintenanceLookup,
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  shipmentType: "",
  modeOfTransport: "",
  entryNo: "",
  importerDocType: "",
  importerRefNo: "",
  dateOfBirth: "",
  countryIssuance: "",
  shipmentSubType: "",
  estimatedValue: "",
  estimatedQuantity: "",
  unitOfMeasure: "",
  estimatedWeight: "",
  weightQualifier: "",
  carnetNumber: "",
  name: "",
  cbpBond: true,
  bondType: "",
  bondHolderDocType: "",
  bondHolderDocNo: "",
  bondTerm: "",
  bondTermRefNo: "",
  bondTermSuretyCode: "",
  agentCode: "",
  scac: "",
  actionCode: "A",
  actionReasonCode: "CT",
  refBookingNo: "",
  portOfLading: "",
  estimateLoadingDate: "",
  cbpTransactionNo: "",
  responseDateTime: "",
  bondNo: "",
  masterBL: "",
  countryUnlading: "",
  foreignPortUnlading: "",
  countryDelivery: "",
  foreignPlaceDelivery: "",
  status: "",
  modeOfSubmission: "",
  templateFlag: false,
  templateName: "",
  partyId: "",
  version: 0,
  isEditTemplate: false,
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  shipments: [],
  parties: [],
  htsList: [],
  containers: [],
  fromTemplate: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACE_ISF5)
  const { securedSendRequest } = useServices()
  const { getValues, setValue } = useFormContext();
  const headerId = getValues('headerId');
  const [user, setUser] = useState([]);
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { filter: { EQUAL } } = constants
  const [blTypes, setBlTypes] = useState([])
  const { getTranslatedText } = useContext(AceIsfTranslationContext)
  const shipments = getValues('shipments')
  const actionCode = useWatch({ name: 'actionCode' });
  const actionReasonCode = useWatch({ name: 'actionReasonCode' });

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHW_CARGO_TYPE' }],
        undefined,
        'code'
      )
    ]).then(
      (blTypesResponse) => {
        setBlTypes(blTypesResponse);
      }
    )
  }, [])

  const memoedHeaderDetailsSection = useMemo(() => (
    <HeaderDetailsSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} blTypes={blTypes} showNotification={showNotification} />
  ), [user])

  const memoedPartiesDetailsSection = useMemo(() => (
    <PartiesDetailsSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} blTypes={blTypes} showNotification={showNotification} />
  ), [])

  const memoedDeliveryLocationSection = useMemo(() => (
    <DeliveryLocationSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} blTypes={blTypes} showNotification={showNotification} />
  ), [])

  const memoedHtsDetailsSection = useMemo(() => (
    <HtsDetailsSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} blTypes={blTypes} showNotification={showNotification} />
  ), [])

  const memoedContainerDetailsSection = useMemo(() => (
    <ContainerDetailsSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} blTypes={blTypes} showNotification={showNotification} />
  ), [])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngSection title={getTranslatedText('ACE_ISF', 'titleIsf5')}>
            {memoedHeaderDetailsSection}
          </CngSection>
          &nbsp;
          <CngSection title={getTranslatedText('ACE_ISF', 'commercialParty')}>
            {memoedPartiesDetailsSection}
          </CngSection>
          &nbsp;
          <CngSection title={getTranslatedText('ACE_ISF', 'deliveryLocation')}>
            {memoedDeliveryLocationSection}
          </CngSection>
          &nbsp;
          <CngSection title={getTranslatedText('ACE_ISF', 'htsDetails')}>
            {memoedHtsDetailsSection}
          </CngSection>
          &nbsp;
          <CngSection title={getTranslatedText('ACE_ISF', 'containerInfo')}>
            {memoedContainerDetailsSection}
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={getTranslatedText('ACE_ISF', 'actionCode')}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} shouldHide={shouldHideMap.actionCode}>
                <CngCodeMasterAutocompleteField
                  required
                  name="actionCode"
                  label={getTranslatedText('ACE_ISF', 'actionCode')}
                  disabled={disabled}
                  codeType='ACEISF_ACTION_CODE'
                  size='small'
                  key={actionCode}
                />
              </CngGridItem>
              {actionCode != 'D' && <>
                <CngGridItem xs={12}>
                  <CngCodeMasterAutocompleteField
                    required={actionCode === 'A' || actionCode === 'R'}
                    name='actionReasonCode'
                    label={getTranslatedText('ACE_ISF', 'actionReasonCode')}
                    disabled={disabled}
                    codeType='ACEISF_ACTION_R_CODE'
                    size='small'
                    key={actionReasonCode}
                  />
                </CngGridItem>
              </>}
            </Grid>
          </CngSection>
        </Grid>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  // let localData = DataFlattener.parse(serverData);
  // localData.dateOfBirth = DateTimeFormatter.toClientDate(
  //   localData.dateOfBirth
  // );
  // localData.estimateLoadingDate = DateTimeFormatter.toClientDate(
  //   localData.estimateLoadingDate
  // );
  // localData.party.forEach((datum) => {
  //   datum.dateOfBirth = DateTimeFormatter.toClientDate(
  //     datum.dateOfBirth
  //   );
  // });
  return serverData;
}

function toServerDataFormat(localData) {
  // localData.dateOfBirth = DateTimeFormatter.toServerDate(
  //   localData.dateOfBirth
  // );
  // localData.estimateLoadingDate = DateTimeFormatter.toServerDate(
  //   localData.estimateLoadingDate
  // );
  // localData.party.forEach((datum) => {
  //   datum.dateOfBirth = DateTimeFormatter.toServerDate(
  //     datum.dateOfBirth
  //   );
  // });
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
