import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices } from 'cng-web-lib'
import { Grid, Divider, Typography } from '@material-ui/core'
import { AceIsfTranslationContext } from '../../contexts/ACEISFTranslationContext'
import { NaCustomsOfficeAutocompleteField, NaSublocationWithoutCodeAutocompleteField } from 'src/components/na'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import Checkbox from 'src/components/Checkbox'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import { useLocation } from 'react-router-dom'
import TemplateSelectDialogWithOptionACIHWYCargo from 'src/components/aciacehighway/TemplateSelectDialogWithOptionACIHWYCargo'
import CngSection from '../../../../components/cngcomponents/CngSection'
import RadioButtonGroup from '../../../../components/aciacehighway/RadioButtonGroup'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from 'src/components/Switch'
import ShipmentFormProperties from '../ShipmentFormProperties'
import ShipmentEntry from '../ShipmentEntry'
import PartyTable from '../PartyTable'
import PartyFormProperties from '../PartyFormProperties'

const {
    button: { CngButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
            CngSelectField
        },
    },
    CngGridItem,
} = components

function PartiesDetailsSection(props) {
    const { disabled, shouldHideMap, user, blTypes, showNotification, shipments } = props
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const { getTranslatedText } = useContext(AceIsfTranslationContext)
    const location = useLocation();
    const { _id: _partyId, parties } = useWatch()

    function handleAddParty(data) {
        const trailerDTO = [...getValues('parties'), data].map(
            (trailerDTO, index) => ({ ...trailerDTO, _id: index + 1 }))

        setValue('parties', trailerDTO, { shouldValidate: true })
    }

    function handleDeleteParty(data) {
        const trailerDTO = [...getValues('parties')].filter(
            (trailerDTO) => trailerDTO.id ? trailerDTO.id !== data.id : trailerDTO._id !== data._id
        )

        setValue('parties', trailerDTO, { shouldValidate: true })
    }

    function handleEditParty(data) {
        const trailerDTO = [...getValues('parties')]
        const index = trailerDTO.findIndex(
            (trailerDTO) => trailerDTO.id ? trailerDTO.id === data.id : trailerDTO._id === data._id
        )

        trailerDTO[index] = data

        setValue('parties', trailerDTO, { shouldValidate: true })
    }

    useEffect(() => {
        if (parties && parties.length === 0) {
            const { initialValues } = PartyFormProperties.formikProps
            initialValues.partyType = "IM";
            setValue('parties', [{ ...initialValues, _partyId, _id: 1 }])

            initialValues.partyType = "CN";
            setValue('parties', [
                ...getValues('parties'),
                { ...initialValues, _partyId, _id: 2 }
            ])

            initialValues.partyType = "SE";
            setValue('parties', [
                ...getValues('parties'),
                { ...initialValues, _partyId, _id: 3 }
            ])

            initialValues.partyType = "BY";
            setValue('parties', [
                ...getValues('parties'),
                { ...initialValues, _partyId, _id: 4 }
            ])

            initialValues.partyType = "ST";
            setValue('parties', [
                ...getValues('parties'),
                { ...initialValues, _partyId, _id: 5 }
            ])

            initialValues.partyType = "CS";
            setValue('parties', [
                ...getValues('parties'),
                { ...initialValues, _partyId, _id: 6 }
            ])

            initialValues.partyType = "LG";
            setValue('parties', [
                ...getValues('parties'),
                { ...initialValues, _partyId, _id: 7 }
            ])

            initialValues.partyType = "MF";
            setValue('parties', [
                ...getValues('parties'),
                { ...initialValues, _partyId, _id: 8 }
            ])
        }
    }, [parties])

    return (
        <PartyTable
            data={parties}
            onAddParty={handleAddParty}
            onDeleteParty={handleDeleteParty}
            onEditParty={handleEditParty}
            showNotification={showNotification}
            isView={false}
        />
    )
}

export default PartiesDetailsSection