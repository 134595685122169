import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, constants } from 'cng-web-lib'
import FormProperties from './PartyFormProperties'
import { Box, Grid, Divider, Typography } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog,
  CngGridItem
} = components

const { FormState } = constants

function PartyDialog(props) {
  const {
    getLookupValue,
    getCountryStateLabel,
    onAddPartyDTO,
    onEditPartyDTO,
    isView,
    onClose,
    open,
    showNotification,
    partyDTO,
    shipmentType
  } = props

  const { initialValues, makeValidationSchema } = FormProperties.formikProps
  const { getTranslatedText } = useContext(AceIsfTranslationContext)

  return (
    <CngDialog
      dialogContent={
        isView && partyDTO ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <CngField label={getTranslatedText('ACE_ISF', 'partyType')}>
                  {getLookupValue('partyType', partyDTO.partyType)}
                </CngField>
              </Grid>
              &nbsp;
              <CngSection title={getTranslatedText('ACE_ISF', 'documentDetails')}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={getTranslatedText('ACE_ISF', 'docType')}>
                      {getLookupValue((partyDTO.partyType === 'IM' || partyDTO.partyType === 'CN') ? 'docTypeIMCN' : 'docType', partyDTO.docType)}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={getTranslatedText('ACE_ISF', 'docNumber')}>
                      {partyDTO.docNumber}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            {(partyDTO.partyType !== 'CN' && partyDTO.partyType !== 'IM') && <>
              <Grid item xs={12}>
                <Grid alignItems='center' container spacing={2}>
                  <CngGridItem xs={12} sm='auto'>
                    <Typography variant='body2'>
                      OR
                    </Typography>
                  </CngGridItem>
                  <CngGridItem xs={12} sm><Divider /></CngGridItem>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CngSection title={getTranslatedText('ACE_ISF', 'partyDetails')}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={getTranslatedText('ACE_ISF', 'partyName')}>
                        {partyDTO.partyName}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={getTranslatedText('ACE_ISF', 'secondaryInfoType')}>
                        {getLookupValue('docType', partyDTO.secondaryInfoType)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={getTranslatedText('ACE_ISF', 'secondaryInfo')}>
                        {partyDTO.secondaryInfo}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <CngField label={getTranslatedText('ACE_ISF', 'address')}>
                        {partyDTO.address}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={getTranslatedText('ACE_ISF', 'city')}>
                        {partyDTO.city}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={getTranslatedText('ACE_ISF', 'country')}>
                        {getLookupValue('docType', partyDTO.country)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={getTranslatedText('ACE_ISF', 'province')}>
                        {getLookupValue('docType', partyDTO.province)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <CngField label={getTranslatedText('ACE_ISF', 'postalCode')}>
                        {partyDTO.postalCode}
                      </CngField>
                    </Grid>
                  </Grid>
                </CngSection>
              </Grid>
            </>}
          </Grid>
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: partyDTO || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (partyDTO) {
                  onEditPartyDTO(data)
                } else {
                  onAddPartyDTO(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <Box padding={1}>
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                  shipmentType={shipmentType}
                />
              </Box>
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton type='submit'>
                  {partyDTO
                    ? getTranslatedText('ACE_ISF', 'updateButton')
                    : getTranslatedText('ACE_ISF', 'saveButton')}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle={getTranslatedText('ACE_ISF', 'commercialParty')}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default PartyDialog
