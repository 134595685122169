import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ISF10Keys from 'src/constants/locale/key/AceIsf'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  headerId: "",
  statusCode: "",
  errorCode: "",
  description: "",
  remarks: "",
  receivedDate: "",
  cbpProcessedDate: "",
  cbpBatchNo: "",
  transactionNo: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ISF10)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let response = translate(
      Namespace.ISF10,
      ISF10Keys.Response.TITLE
    )
    let id = translate(
      Namespace.ISF10,
      ISF10Keys.Response.ID
    )
    let headerId = translate(
      Namespace.ISF10,
      ISF10Keys.Response.HEADER_ID
    )
    let statusCode = translate(
      Namespace.ISF10,
      ISF10Keys.Response.STATUS_CODE
    )
    let errorCode = translate(
      Namespace.ISF10,
      ISF10Keys.Response.ERROR_CODE
    )
    let description = translate(
      Namespace.ISF10,
      ISF10Keys.Response.DESCRIPTION
    )
    let remarks = translate(
      Namespace.ISF10,
      ISF10Keys.Response.REMARKS
    )
    let receivedDate = translate(
      Namespace.ISF10,
      ISF10Keys.Response.RECEIVED_DATE
    )
    let cbpProcessedDate = translate(
      Namespace.ISF10,
      ISF10Keys.Response.CBP_PROCESSED_DATE
    )
    let cbpBatchNo = translate(
      Namespace.ISF10,
      ISF10Keys.Response.CBP_BATCH_NO
    )
    let transactionNo = translate(
      Namespace.ISF10,
      ISF10Keys.Response.TRANSACTION_NO
    )

    return {
      response,
      id,
      headerId,
      statusCode,
      errorCode,
      description,
      remarks,
      receivedDate,
      cbpProcessedDate,
      cbpBatchNo,
      transactionNo
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.response} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
            <CngTextField
              name="id"
              label={translatedTextsObject.id}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.headerId}>
            <CngTextField
              name="headerId"
              label={translatedTextsObject.headerId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.statusCode}>
            <CngTextField
              name="statusCode"
              label={translatedTextsObject.statusCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.errorCode}>
            <CngTextField
              name="errorCode"
              label={translatedTextsObject.errorCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description}>
            <CngTextField
              name="description"
              label={translatedTextsObject.description}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.remarks}>
            <CngTextField
              name="remarks"
              label={translatedTextsObject.remarks}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.receivedDate}>
            <CngTextField
              name="receivedDate"
              label={translatedTextsObject.receivedDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cbpProcessedDate}>
            <CngTextField
              name="cbpProcessedDate"
              label={translatedTextsObject.cbpProcessedDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cbpBatchNo}>
            <CngTextField
              name="cbpBatchNo"
              label={translatedTextsObject.cbpBatchNo}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transactionNo}>
            <CngTextField
              name="transactionNo"
              label={translatedTextsObject.transactionNo}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const ResponseFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ResponseFormProperties
