import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
// import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
// import React, { useEffect, useState, useContext, useMemo } from 'react'
import moment from 'moment'
import Isf10 from 'src/constants/locale/key/AceIsf'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants
// const { getTranslatedText } = useContext(AceIsfTranslationContext)

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let dobDateRegx = translate(
    Namespace.ACE_ISF,
    Isf10.Validation.DOB_DATE_REGEX
  )
  let estimateLoadingDateDateRegx = translate(
    Namespace.ACE_ISF,
    Isf10.Validation.ESTIMATE_LOADING_DATE_DATE_REGEX
  )

  let regexEI = "^\\d{2}-\\d{7}[a-zA-Z0-9]{2}$"
  let regex34 = "^\\d{3}\\-\\d{2}\\-\\d{4}$"
  let regexANI = "^\\d{6}\\-\\d{5}$"
  let regexAEF = "^[a-zA-Z0-9]+$"
  let regexNumeric = '^[0-9]*$'

  return Yup.object({
    shipmentType: Yup.string().required(requiredMessage).nullable(),
    modeOfTransport: Yup.string().required(requiredMessage).nullable(),
    entryNo: Yup.string().matches(/^[a-zA-Z0-9\-]+$/, "Please enter only alphanumeric(without spaces)").nullable(),
    masterBL: Yup.string().matches(/^[a-zA-Z0-9\-]+$/, "Please enter only alphanumeric(without spaces)").nullable(),
    importerDocType: Yup.string().required(requiredMessage).nullable(),
    importerRefNo:
      Yup.string().when('importerDocType', {
        is: (importerDocType) => (importerDocType === 'EI'),
        then: Yup.string().nullable().matches(regexEI, "Incorrect Importer Document Number format: NN-NNNNNNNXX")
      })
        .when('importerDocType', {
          is: (importerDocType) => (importerDocType === '34'),
          then: Yup.string().nullable().matches(regex34, "Incorrect Importer Document Number format: NNN-NN-NNNN")
        })
        .when('importerDocType', {
          is: (importerDocType) => (importerDocType === 'ANI'),
          then: Yup.string().nullable().matches(regexANI, "Incorrect Importer Document Number format: YYDDPP-NNNNN")
        })
        .when('importerDocType', {
          is: (importerDocType) => (importerDocType === 'AEF'),
          then: Yup.string().nullable().matches(regexAEF, "Incorrect Importer Document Number format: XXXXXXXXXXXXXXX")
        })
        .when('templateFlag', {
          is: (templateFlag) => (!templateFlag),
          then: Yup.string().required(requiredMessage)
        })
        .nullable(),
    dateOfBirth: Yup.string().when('importerDocType', {
      is: (value) => value === "AEF",
      then: Yup.string().required(requiredMessage).test(
        'dateOfBirth',
        dobDateRegx,
        value => {
          return moment(value, 'YYYY-MM-DD').isValid()
        },
      ), otherwise: Yup.string()
    }).nullable(),
    countryIssuance: Yup.string().nullable(),
    name: Yup.string().matches(/^[a-zA-Z0-9- ]+$/, "Please enter only alphanumeric").nullable(),
    cbpBond: Yup.string().required(requiredMessage).nullable(),
    bondType: Yup.string().when('cbpBond', {
      is: (cbpBond) => cbpBond === "true",
      then: Yup.string().required(requiredMessage).nullable()
    }).nullable(),
    bondHolderDocType: Yup.string().when('cbpBond', {
      is: (cbpBond) => cbpBond === "true",
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    bondHolderDocNo: Yup.string().when('cbpBond', {
      is: (cbpBond) => cbpBond === "true",
      then:
        Yup.string().when('bondHolderDocType', {
          is: (bondHolderDocType) => (bondHolderDocType === 'EI'),
          then: Yup.string().nullable().matches(regexEI, "Incorrect Bond Holder Document Number format: NN-NNNNNNNXX")
        })
          .when('bondHolderDocType', {
            is: (bondHolderDocType) => (bondHolderDocType === '34'),
            then: Yup.string().nullable().matches(regex34, "Incorrect Bond Holder Document Number format: NNN-NN-NNNN")
          })
          .when('bondHolderDocType', {
            is: (bondHolderDocType) => (bondHolderDocType === 'ANI'),
            then: Yup.string().nullable().matches(regexANI, "Incorrect Bond Holder Document Number format: YYDDPP-NNNNN")
          })
          .required(requiredMessage).nullable(),
    }).nullable(),
    bondTerm: Yup.string().when('cbpBond', {
      is: (cbpBond) => cbpBond === "true",
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    bondTermRefNo: Yup.string().when('bondType', {
      is: (bondType) => bondType === "16",
      then: Yup.string().when('bondTerm', {
        is: (bondTerm) => bondTerm === "9",
        then: Yup.string().required(requiredMessage).nullable(),
      })
    }).nullable(),
    bondTermSuretyCode: Yup.string().when('bondType', {
      is: (bondType) => bondType === "16",
      then: Yup.string().when('bondTerm', {
        is: (bondTerm) => bondTerm === "9",
        then: Yup.string().required(requiredMessage).nullable(),
      })
    }).nullable(),
    agentCode: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9\-]+$/, "Please enter only alphanumeric(without spaces)").nullable(),
    scac: Yup.string().matches(/^[a-zA-Z0-9\-]+$/, "Please enter only alphanumeric(without spaces)").nullable(),
    actionCode: Yup.string().when('status', {
      is: (value) => value === "1000" || value === "1006",
      then: Yup.string().test('actionCode', "You cannot send message function code as Add for Accepted record", function (value) {
        if (value == "A") {
          return false;
        } else {
          return true;
        }
      }),
      otherwise: Yup.string().when('status', {
        is: (value) => value === "1005",
        then: Yup.string().test('actionCode', "You cannot send message function code as Replace/Delete for Draft record", function (value) {
          if (value != "A") {
            return false;
          } else {
            return true;
          }
        }),
        otherwise: Yup.string().when('status', {
          is: (value) => value === "1003",
          then: Yup.string().test('actionCode', "You cannot send message function code as Replace/Delete for Cancelled record", function (value) {
            if (value != "A") {
              return false;
            } else {
              return true;
            }
          }),
          otherwise: Yup.string().when('status', {
            is: (value) => value === "1007",
            then: Yup.string().test('actionCode', "You cannot send message function code as Add for Modification rejected record", function (value) {
              if (value === "A") {
                return false;
              } else {
                return true;
              }
            })
          }),
          otherwise: Yup.string().when('status', {
            is: (value) => value === "1001",
            then: Yup.string().test('actionCode', "You cannot send message function code as Add/Delete for Rejected record", function (value) {
              if (value !== "R") {
                return false;
              } else {
                return true;
              }
            })
          }),
          otherwise: Yup.string().when('status', {
            is: (value) => value === "1004",
            then: Yup.string().test('actionCode', "You can not send message function code as Add for Cancellation rejected record", function (value) {
              if (value === "A") {
                return false;
              } else {
                return true;
              }
            })
          }),
        })
      })
    }).nullable().required(requiredMessage),
    actionReasonCode: Yup.string().when('actionCode', {
      is: (actionCode) => (actionCode === 'A' || actionCode === 'R'),
      then: Yup.string().required(requiredMessage).nullable()
    }).nullable(),
    refBookingNo: Yup.string().nullable(),
    portOfLading: Yup.string().nullable(),
    // estimateLoadingDate: Yup.string().when('estimateLoadingDate', {
    //   is: (value) => value.length > 0,
    //   then: Yup.string().test(
    //     'estimateLoadingDate',
    //     estimateLoadingDateDateRegx,
    //     value => {
    //       return moment(value, 'YYYY-MM-DD').isValid()
    //     },
    //   ), otherwise: Yup.string()
    // }).nullable(),
    responseDateTime: Yup.string().nullable(),
    bondNo: Yup.string().nullable(),
    modeOfSubmission: Yup.string().nullable(),
    // templateFlag: Yup.string().nullable(),
    templateName: Yup.string().when('templateFlag', {
      is: true,
      then: Yup.string().trim().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like ),(,@ etc."),
    }).nullable(),
    shipmentSubType: Yup.string().nullable(),
    estimatedValue: Yup.string().when('shipmentType', {
      is: (shipmentType) => (shipmentType === '11'),
      then: Yup.string().nullable().matches(regexNumeric, "Only digits are allowed")
    }).nullable(),
    estimatedQuantity: Yup.string().when('shipmentType', {
      is: (shipmentType) => (shipmentType === '11'),
      then: Yup.string().nullable().matches(regexNumeric, "Only digits are allowed")
    }).nullable(),
    unitOfMeasure: Yup.string().nullable(),
    estimatedWeight: Yup.string().when('shipmentType', {
      is: (shipmentType) => (shipmentType === '11'),
      then: Yup.string().nullable().matches(regexNumeric, "Only digits are allowed")
    }).nullable(),
    weightQualifier: Yup.string().nullable(),
    shipments: Yup.array().of(Yup.object({
      blType: Yup.string().required(requiredMessage).nullable(),
      blNo: Yup.string().matches(/^[a-zA-Z0-9\-]+$/, "Please enter only alphanumeric(without spaces)").nullable(),
      blMatched: Yup.string().nullable(),
    })),
  })
}

export default makeValidationSchema
