import makeValidationSchema from './HtsMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext } from 'react'
import Namespace from 'src/constants/locale/Namespace'

import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import { useFormContext, useWatch } from 'react-hook-form';

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _htsId: 0,
  id:"",
  manufacturerPartyId: "",
  htsNo: "",
  countryOrigin: "",
  version: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { getTranslatedText } = useContext(AceIsfTranslationContext)
  const { getValues, setValue, reset, trigger } = useFormContext();

  return (
    <Card>
      {/* <CngSimpleCardHeader title={translatedTextsObject.shipment} /> */}
      <CardContent>
        <Grid container spacing={2}>
          <CngGridItem xs={6} sm={6} shouldHide={shouldHideMap.htsNo}>
            <CngTextField
              required
              name={typeof index === 'number' ? `htsList.${index}.htsNo` : 'htsNo'}
              label={getTranslatedText('ACE_ISF', 'htsNo')}
              disabled={disabled}
              inputProps={{ maxLength: 10 }}
              onBlur={(e) => {
                setValue('htsNo', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={6} sm={6} shouldHide={shouldHideMap.countryOrigin}>
            <CngCountryAutocompleteField
              required
              name={typeof index === 'number' ? `htsList.${index}.countryOrigin` : 'countryOrigin'}
              label={getTranslatedText('ACE_ISF', 'countryOrigin')}
              disabled={disabled}
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const HtsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default HtsFormProperties
