import React, { useEffect, useRef, useState, useContext } from 'react'
import { Chip } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import moment from 'moment-timezone'
import ISF10ApiUrls from 'src/apiUrls/ISF10ApiUrls'
import { DateTimeFormatter, constants } from 'cng-web-lib'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import { useHistory } from 'react-router-dom'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'

function TemplateSelectDialogWithOption(props) {
  const {
    isfTemplateDialog,
    setIsfTemplateDialog,
    setConfirmDialog,
    showNotification,
    translatedTextsObject,
    setLicensePlateCount,
    setValue,
    rowActions,
    reset,
    trigger,
    isTable,
    location,
    setOnChangeCarrierCode,
    setRefreshCarrierCodeAndTripNo
  } = props

  const {
    filter: { LIKE, EQUAL }
  } = constants

  const history = useHistory()
  const { getTranslatedText } = useContext(AceIsfTranslationContext)

  function handleApplyIsfTemplate(template) {
    if (template) {
      const {
        shipmentType,
        modeOfTransport,
        entryNo,
        importerDocType,
        importerRefNo,
        dateOfBirth,
        countryIssuance,
        shipmentSubType,
        estimatedValue,
        estimatedQuantity,
        unitOfMeasure,
        estimatedWeight,
        weightQualifier,
        carnetNumber,
        name,
        cbpBond,
        bondType,
        bondHolderDocType,
        bondHolderDocNo,
        bondTerm,
        bondTermRefNo,
        bondTermSuretyCode,
        agentCode,
        scac,
        actionCode,
        actionReasonCode,
        refBookingNo,
        portOfLading,
        estimateLoadingDate,
        bondNo,
        masterBL,
        version,
        shipments,
        parties,
        containers
      } = template

      var formattedEstimateLoadingDate = estimateLoadingDate ? moment(estimateLoadingDate).tz("Canada/Eastern").format('YYYY-MM-DD') : "";

      var countShipment = 1;
      shipments.forEach((shipment) => {
        shipment.id = null;
        shipment._id = countShipment++;
        shipment.createdBy = null;
        shipment.createdDate = null;
        shipment.updatedBy = null;
        shipment.updatedDate = null;
      });

      var countParty = 1;
      var countHTS = 1;
      parties.forEach((party) => {
        party.id = null;
        party._id = countParty++;
        party.createdBy = null;
        party.createdDate = null;
        party.updatedBy = null;
        party.updatedDate = null;
        party.htsList.forEach((hts) => {
          hts.id = null;
          hts._id = countHTS++;
          hts.createdBy = null;
          hts.createdDate = null;
          hts.updatedBy = null;
          hts.updatedDate = null;
        });
      });

      var countContainer = 1;
      containers.forEach((container) => {
        container.id = null;
        container._id = countContainer++;
        container.createdBy = null;
        container.createdDate = null;
        container.updatedBy = null;
        container.updatedDate = null;
      });

      if (isTable) {
        var templateJson = {
          "id": null,
          "version": 0,
          "baseFilterDTO": null,
          "errorMessages": null,
          "customData": null,
          "createdBy": null,
          "createdDate": null,
          "updatedBy": null,
          "updatedDate": null,
          "partyId": null,
          "status": "",
          "templateFlag": false,
          "templateName": "",
          "shipmentType": shipmentType,
          "modeOfTransport": modeOfTransport,
          "entryNo": entryNo,
          "importerDocType": importerDocType,
          "importerRefNo": importerRefNo,
          "dateOfBirth": dateOfBirth,
          "countryIssuance": countryIssuance,
          "shipmentSubType": shipmentSubType,
          "estimatedValue": estimatedValue,
          "estimatedQuantity": estimatedQuantity,
          "unitOfMeasure": unitOfMeasure,
          "estimatedWeight": estimatedWeight,
          "weightQualifier": weightQualifier,
          "carnetNumber": carnetNumber,
          "name": name,
          "cbpBond": cbpBond,
          "bondType": bondType,
          "bondHolderDocType": bondHolderDocType,
          "bondHolderDocNo": bondHolderDocNo,
          "bondTerm": bondTerm,
          "bondTermRefNo": bondTermRefNo,
          "bondTermSuretyCode": bondTermSuretyCode,
          "agentCode": agentCode,
          "scac": scac,
          "actionCode": actionCode,
          "actionReasonCode": actionReasonCode,
          "refBookingNo": refBookingNo,
          "portOfLading": portOfLading,
          "estimateLoadingDate": formattedEstimateLoadingDate,
          "bondNo": bondNo,
          "masterBL": masterBL,
          "shipments": shipments,
          "parties": parties,
          "containers": containers,
          "fromTemplate": true
        }

        history.push(`${location.pathname}/add`, templateJson)

      } else {
        setValue('shipmentType', shipmentType || '', { shouldDirty: true })
        setValue('modeOfTransport', modeOfTransport || '', { shouldDirty: true })
        setValue('entryNo', entryNo || '', { shouldDirty: true })
        setValue('importerDocType', importerDocType || '', { shouldDirty: true })
        setValue('importerRefNo', importerRefNo || '', { shouldDirty: true })
        setValue('dateOfBirth', dateOfBirth || '', { shouldDirty: true })
        setValue('countryIssuance', countryIssuance || '', { shouldDirty: true })
        setValue('shipmentSubType', shipmentSubType || '', { shouldDirty: true })
        setValue('estimatedValue', estimatedValue || '', { shouldDirty: true })
        setValue('estimatedQuantity', estimatedQuantity || '', { shouldDirty: true })
        setValue('unitOfMeasure', unitOfMeasure || '', { shouldDirty: true })
        setValue('estimatedWeight', estimatedWeight || '', { shouldDirty: true })
        setValue('weightQualifier', weightQualifier || '', { shouldDirty: true })
        setValue('carnetNumber', carnetNumber || '', { shouldDirty: true })
        setValue('name', name || '', { shouldDirty: true })
        setValue('cbpBond', cbpBond || '', { shouldDirty: true })
        setValue('bondType', bondType || '', { shouldDirty: true })
        setValue('bondHolderDocType', bondHolderDocType || '', { shouldDirty: true })
        setValue('bondHolderDocNo', bondHolderDocNo || '', { shouldDirty: true })
        setValue('bondTerm', bondTerm || '', { shouldDirty: true })
        setValue('bondTermRefNo', bondTermRefNo || '', { shouldDirty: true })
        setValue('bondTermSuretyCode', bondTermSuretyCode || '', { shouldDirty: true })
        setValue('agentCode', agentCode || '', { shouldDirty: true })
        setValue('scac', scac || '', { shouldDirty: true })
        setValue('actionCode', actionCode || '', { shouldDirty: true })
        setValue('actionReasonCode', actionReasonCode || '', { shouldDirty: true })
        setValue('refBookingNo', refBookingNo || '', { shouldDirty: true })
        setValue('portOfLading', portOfLading || '', { shouldDirty: true })
        setValue('estimateLoadingDate', formattedEstimateLoadingDate || '', { shouldDirty: true })
        setValue('bondNo', bondNo || '', { shouldDirty: true })
        setValue('shipments', shipments || '', { shouldDirty: true })
        setValue('parties', parties || '', { shouldDirty: true })
        setValue('containers', containers || '', { shouldDirty: true })
        setValue('version', version || '', { shouldDirty: true })
        setValue('fromTemplate', true, { shouldDirty: true })

        showNotification('success', 'Template applied successfully.')
        trigger()
      }
    } else {
      reset()
    }
  }

  const columns = [
    {
      field: 'id',
      title: 'ID'
    },
    {
      field: 'templateName',
      title: 'TEMPLATE NAME'
    },
    {
      field: 'status',
      sortKey: 'status',
      title: getTranslatedText('ACE_ISF', 'status'),
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      }
    },
    {
      field: 'createdDate',
      //sortKey: 'CREATED DATE',
      title: 'CREATED DATE',
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'updatedDate',
      //sortKey: 'UPDATED DATE',
      title: 'UPDATED DATE',
      render: (data) =>
        data.updatedDate && moment(data.updatedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    isTable ? {
      title: 'Action'
    } : {}
  ]

  const sortOptions = [
    // {
    //   label: translatedTextsObject.status,
    //   value: 'status'
    // }
  ]

  return (
    <TemplateSelectDialog
      columns={columns}
      defaultSorts={[{ field: 'createdDate', direction: 'DESC' }]}
      fetch={{ url: ISF10ApiUrls.GET }}
      fetchFilters={
        [
          { field: 'templateFlag', operator: 'equal', value: true },
          { field: 'status', operator: 'equal', value: '1002' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]
      }
      onApplyTemplate={handleApplyIsfTemplate}
      onClose={() => setIsfTemplateDialog(false)}
      open={isfTemplateDialog}
      search={{ field: 'templateName', operator: LIKE }}
      sortOptions={sortOptions}
      title='ISF 10 template'
      rowActions={rowActions}
      isTable={isTable}
    />
  )
}

export default TemplateSelectDialogWithOption