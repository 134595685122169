import React from 'react'
import { components } from 'cng-web-lib'
import ShipmentFormProperties from './ShipmentFormProperties'
import { Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngIconButton }
} = components

const { initialValues } = ShipmentFormProperties.formikProps

function ShipmentEntry(props) {
  const {
    index,
    isLast,
    onAddShipment,
    onDeleteShipment,
    shipment,
    showNotification,
    shouldHideMap
  } = props

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md>
        <ShipmentFormProperties.Fields index={index} showNotification={showNotification} shouldHideMap={shouldHideMap} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddShipment(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddShipment({ ...shipment, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeleteShipment(shipment)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ShipmentEntry
